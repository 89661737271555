import Api from '@/services/Api'

export default {
    createCompany (form) {
      return Api().post('companies/create', form)
    },
    grabeFormato (form) {
      return Api().post('formatos/update', form)
    },
    getAllCompanies(){
        return Api().get('companies')
    },
    getAllSubsidiarias(){
        return Api().get('subsidiarias')
    },
    getAllFormatos(){
        return Api().get('formatos')
    },
    getCompanyById(id){
      return Api().get('companiById/'+ id)
    },
    leaUsuarios(id_compania){
      return Api().get('leaUsuarios/'+ id_compania)
    },
    updateCompany (form) {
      return Api().put('companies/update', form)
    },
    grabaCompaniaUsuario (data) {
      return Api().put('companies/grabaCompaniaUsuario', data)
    },
    inactivateCompany (id) {
      return Api().put('companies/inactivate/'+ id)
    },
    deleteCompany (id) {
      return Api().put('companies/delete/'+ id)
    },
    retiraUsuario(r_usuario) {
      return Api().put('companies/retiraUsuario/'+r_usuario.id_compania+"/"+r_usuario.id_usuario)
    },
  }
  
