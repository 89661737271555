<template>
    <div class="container">
        <b-row>
            <b-container>
                <h3 class="text-center py-2" v-if="!generado">
                    <b><i>{{f_titulo()}}</i></b>
		</h3>
                <h6 class="text-center py-2">
			<label>Reporte:</label>
			<select v-model="dialogReportes" @click="isEmpty=true;">
			<option
				v-for="(item, idx) in reportes"
				:key="'codigo-'+idx"
				:value="item.codigo">
				{{item.codigo}}-{{item.nombre}}
			</option>
			</select> &nbsp;&nbsp;
			<label>Vigencias:</label>
			<select v-model="dialogFechas" @click="isEmpty=true;">
			<option
				v-for="(item, idx) in fechas"
				:key="'fecha-'+idx"
				:value="item.fecha">
				{{item.fecha}}
			</option>
			</select> &nbsp;&nbsp;
			<label>Compañias:</label>
                    <select v-model="dialogCompanias" v-on:click="seleccionaCompania()" >
                      <option
                        v-for="(item, idx) in companias"
                        :key="'compania-'+idx"
                        :value="item.id">
                        {{item.acronym+" "+item.nombre}}
                      </option>
                    </select> &nbsp;&nbsp;<br>
		<b-container v-if="this.dialogFechas && this.dialogCompanias">
                    <label for="comparativo">{{labels.comparativo}}</label>
			&nbsp;
                    <input type="checkbox" id="comparativo" value="Comparativo" v-on:click="uncheck(1)" > &nbsp; &nbsp;
                    <label for="directo">{{labels.directo}}</label>
			&nbsp;
                    <input type="checkbox" id="directo" value="Directo" v-on:click="uncheck(2)" > &nbsp; &nbsp;
                    <label for="indirecto">{{labels.indirecto}}</label> &nbsp;
                    <input type="checkbox" id="indirecto" value="Indirecto" v-on:click="uncheck(3)"> &nbsp;&nbsp;		
                    <label for="informacion">{{labels.informaciond}}</label>
			&nbsp;
                    <input type="checkbox" id="informacion" value="Informacion" v-on:click="uncheck_nivel(0)" > &nbsp; &nbsp;
                    <label for="nivel">{{labels.nivel}} 1:</label> &nbsp;
                    <input type="checkbox" id="nivel_1" value="nivel_1" v-on:click="uncheck_nivel(1)"> &nbsp;&nbsp;		
                    <label for="nivel">2:</label> &nbsp;
                    <input type="checkbox" id="nivel_2" value="nivel_2" v-on:click="uncheck_nivel(2)" > &nbsp;&nbsp;		
                    <label for="nivel">3:</label> &nbsp;
                    <input type="checkbox" id="nivel_3" value="nivel_3" v-on:click="uncheck_nivel(3)" > &nbsp;&nbsp;		
                    <label for="nivel">4:</label> &nbsp;
                    <input type="checkbox" id="nivel_4" value="nivel_4" v-on:click="uncheck_nivel(4)" > &nbsp;&nbsp;		
                    <label for="consolidado">{{labels.consolidado}}</label>
			&nbsp;
                    <input type="checkbox" id="consolidado" value="Consolidado" v-on:click="uncheck(4)" > &nbsp; &nbsp;
                    <b-button v-if="informacion||comparativo||directo||indirecto||consolidado" variant="outline-primary" @click="generar">
			<h4><b><i>{{labels.generar}}</i></b></h4>
                    </b-button>
		</b-container>

                </h6>
            </b-container>
        </b-row>
        <b-row>
            <b-container v-if="!isEmpty && comparativo" class="pb-5">
                <md-table v-model="balance" md-card md-fixed-header>
			<md-table-toolbar>
				<h1 class="md-title"><b><i>{{f_titulo()}}</i></b> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        <md-button v-if="fgl_excel" @click="excel(1)" class = "md-fab md-primary"><md-icon><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" /></svg>
</md-icon></md-button>
				</h1>
			</md-table-toolbar>
			<md-table-row slot="md-table-row" slot-scope="{ item }">
				<md-table-cell :md-label="labels.descripcion" class="text-left border">{{item.descripcion}}</md-table-cell>
				<md-table-cell :md-label="labels.saldo_anterior" class="text-right border" v-if="f_escribe_renglon(item.informacion)">{{ formato(item.anterior) }}</md-table-cell>
				<md-table-cell :md-label="labels.saldo_anterior" v-if="!f_escribe_renglon(item.informacion)" class="text-right border" ></md-table-cell>
				<md-table-cell :md-label="labels.saldo_actual" class="text-right border" v-if="f_escribe_renglon(item.informacion)">{{ formato(item.actual) }}</md-table-cell>
				<md-table-cell :md-label="labels.saldo_actual" v-if="!f_escribe_renglon(item.informacion)" class="text-right border" ></md-table-cell>
				<md-table-cell :md-label="labels.diferencia" class="text-right border" v-if="f_escribe_renglon(item.informacion)">{{ formato(item.diferencia) }}</md-table-cell>
				<md-table-cell :md-label="labels.diferencia" v-if="!f_escribe_renglon(item.informacion)" class="text-right border" ></md-table-cell>
			</md-table-row>
                </md-table>
            </b-container>
            <b-container v-if="!isEmpty && (directo || indirecto) && !informacion && !consolidado" class="pb-5">
                <md-table v-model="balance" md-card md-fixed-header>
			<md-table-toolbar>
				<h1 class="md-title"><b><i>{{f_titulo()}}</i></b> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        <md-button v-if="fgl_excel" @click="excel(2)" class = "md-fab md-primary"><md-icon><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" /></svg>
</md-icon></md-button>
				</h1>
			</md-table-toolbar>
			<md-table-row slot="md-table-row" slot-scope="{ item }">
				<md-table-cell :md-label="labels.descripcion" class="text-left border">{{ item.descripcion }}</md-table-cell>
				<md-table-cell :md-label="labels.monto" class="text-right border" v-if="f_escribe_renglon(item.tipo)">{{ formato(item.valor) }}</md-table-cell>
				<md-table-cell :md-label="labels.monto" class="text-right border" v-if="!f_escribe_renglon(item.tipo)"></md-table-cell>
			</md-table-row>
                </md-table>
            </b-container>
            <b-container v-if="!isEmpty && (directo || indirecto) && !informacion && consolidado" class="pb-5">
                <md-table v-model="balance" md-card md-fixed-header>
			<md-table-toolbar>
				<h1 class="md-title"><b><i>{{f_titulo()}}</i></b> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        <md-button v-if="fgl_excel" @click="excel(4)" class = "md-fab md-primary"><md-icon><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" /></svg>
</md-icon></md-button>
				</h1>
			</md-table-toolbar>
			<md-table-row slot="md-table-row" slot-scope="{ item }">
				<md-table-cell :md-label="labels.descripcion" class="text-left border">{{ item.descripcion }}</md-table-cell>

			<md-table-cell :md-label="titulo_columna[0]" class="text-right border" v-if="columnas>=1 && f_escribe_renglon(item.informacion)">{{ formato(item.c1) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[0]" class="text-right border" v-if="columnas>=1 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[1]" class="text-right border" v-if="columnas>=2 && f_escribe_renglon(item.informacion)">{{ formato(item.c2) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[1]" class="text-right border" v-if="columnas>=2 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[2]" class="text-right border" v-if="columnas>=3 && f_escribe_renglon(item.informacion)">{{ formato(item.c3) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[2]" class="text-right border" v-if="columnas>=3 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[3]" class="text-right border" v-if="columnas>=4 && f_escribe_renglon(item.informacion)">{{ formato(item.c4) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[3]" class="text-right border" v-if="columnas>=4 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[4]" class="text-right border" v-if="columnas>=5 && f_escribe_renglon(item.informacion)">{{ formato(item.c5) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[4]" class="text-right border" v-if="columnas>=5 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[5]" class="text-right border" v-if="columnas>=6 && f_escribe_renglon(item.informacion)">{{ formato(item.c6) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[5]" class="text-right border" v-if="columnas>=6 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[6]" class="text-right border" v-if="columnas>=7 && f_escribe_renglon(item.informacion)">{{ formato(item.c7) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[6]" class="text-right border" v-if="columnas>=7 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[7]" class="text-right border" v-if="columnas>=8 && f_escribe_renglon(item.informacion)">{{ formato(item.c8) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[7]" class="text-right border" v-if="columnas>=8 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[8]" class="text-right border" v-if="columnas>=9 && f_escribe_renglon(item.informacion)">{{ formato(item.c9) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[8]" class="text-right border" v-if="columnas>=9 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[9]" class="text-right border" v-if="columnas>=10 && f_escribe_renglon(item.informacion)">{{ formato(item.c10) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[9]" class="text-right border" v-if="columnas>=10 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[10]" class="text-right border" v-if="columnas>=11 && f_escribe_renglon(item.informacion)">{{ formato(item.c11) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[10]" class="text-right border" v-if="columnas>=11&& !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[11]" class="text-right border" v-if="columnas>=12 && f_escribe_renglon(item.informacion)">{{ formato(item.c12) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[11]" class="text-right border" v-if="columnas>=12 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[12]" class="text-right border" v-if="columnas>=13 && f_escribe_renglon(item.informacion)">{{ formato(item.c13) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[12]" class="text-right border" v-if="columnas>=13 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[13]" class="text-right border" v-if="columnas>=14 && f_escribe_renglon(item.informacion)">{{ formato(item.c14) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[13]" class="text-right border" v-if="columnas>=14 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[14]" class="text-right border" v-if="columnas>=15 && f_escribe_renglon(item.informacion)">{{ formato(item.c15) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[14]" class="text-right border" v-if="columnas>=15 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[15]" class="text-right border" v-if="columnas>=16 && f_escribe_renglon(item.informacion)">{{ formato(item.c16) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[15]" class="text-right border" v-if="columnas>=16 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[16]" class="text-right border" v-if="columnas>=17 && f_escribe_renglon(item.informacion)">{{ formato(item.c17) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[16]" class="text-right border" v-if="columnas>=17 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[17]" class="text-right border" v-if="columnas>=18 && f_escribe_renglon(item.informacion)">{{ formato(item.c18) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[17]" class="text-right border" v-if="columnas>=18 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[18]" class="text-right border" v-if="columnas>=19 && f_escribe_renglon(item.informacion)">{{ formato(item.c19) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[18]" class="text-right border" v-if="columnas>=19 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[19]" class="text-right border" v-if="columnas>=20 && f_escribe_renglon(item.informacion)">{{ formato(item.c20) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[19]" class="text-right border" v-if="columnas>=20 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[20]" class="text-right border" v-if="columnas>=21 && f_escribe_renglon(item.informacion)">{{ formato(item.c21) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[20]" class="text-right border" v-if="columnas>=21 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[21]" class="text-right border" v-if="columnas>=22 && f_escribe_renglon(item.informacion)">{{ formato(item.c22) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[21]" class="text-right border" v-if="columnas>=22 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[22]" class="text-right border" v-if="columnas>=23 && f_escribe_renglon(item.informacion)">{{ formato(item.c23) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[22]" class="text-right border" v-if="columnas>=23 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[23]" class="text-right border" v-if="columnas>=24 && f_escribe_renglon(item.informacion)">{{ formato(item.c24) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[23]" class="text-right border" v-if="columnas>=24 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[24]" class="text-right border" v-if="columnas>=25 && f_escribe_renglon(item.informacion)">{{ formato(item.c25) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[24]" class="text-right border" v-if="columnas>=25 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[25]" class="text-right border" v-if="columnas>=26 && f_escribe_renglon(item.informacion)">{{ formato(item.c26) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[25]" class="text-right border" v-if="columnas>=26 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[26]" class="text-right border" v-if="columnas>=27 && f_escribe_renglon(item.informacion)">{{ formato(item.c27) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[26]" class="text-right border" v-if="columnas>=27 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[27]" class="text-right border" v-if="columnas>=28 && f_escribe_renglon(item.informacion)">{{ formato(item.c28) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[27]" class="text-right border" v-if="columnas>=28 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[28]" class="text-right border" v-if="columnas>=29 && f_escribe_renglon(item.informacion)">{{ formato(item.c29) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[28]" class="text-right border" v-if="columnas>=29 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[29]" class="text-right border" v-if="columnas>=30 && f_escribe_renglon(item.informacion)">{{ formato(item.c30) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[29]" class="text-right border" v-if="columnas>=30 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[30]" class="text-right border" v-if="columnas>=31 && f_escribe_renglon(item.informacion)">{{ formato(item.c31) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[30]" class="text-right border" v-if="columnas>=31 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[31]" class="text-right border" v-if="columnas>=32 && f_escribe_renglon(item.informacion)">{{ formato(item.c32) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[31]" class="text-right border" v-if="columnas>=32 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[32]" class="text-right border" v-if="columnas>=33 && f_escribe_renglon(item.informacion)">{{ formato(item.c33) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[32]" class="text-right border" v-if="columnas>=33 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[33]" class="text-right border" v-if="columnas>=34 && f_escribe_renglon(item.informacion)">{{ formato(item.c34) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[33]" class="text-right border" v-if="columnas>=34 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[34]" class="text-right border" v-if="columnas>=35 && f_escribe_renglon(item.informacion)">{{ formato(item.c35) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[34]" class="text-right border" v-if="columnas>=35 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[35]" class="text-right border" v-if="columnas>=36 && f_escribe_renglon(item.informacion)">{{ formato(item.c36) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[35]" class="text-right border" v-if="columnas>=36 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[36]" class="text-right border" v-if="columnas>=37 && f_escribe_renglon(item.informacion)">{{ formato(item.c37) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[36]" class="text-right border" v-if="columnas>=37 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[37]" class="text-right border" v-if="columnas>=38 && f_escribe_renglon(item.informacion)">{{ formato(item.c38) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[37]" class="text-right border" v-if="columnas>=38 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[38]" class="text-right border" v-if="columnas>=39 && f_escribe_renglon(item.informacion)">{{ formato(item.c39) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[38]" class="text-right border" v-if="columnas>=39 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[39]" class="text-right border" v-if="columnas>=40 && f_escribe_renglon(item.informacion)">{{ formato(item.c40) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[39]" class="text-right border" v-if="columnas>=40 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[40]" class="text-right border" v-if="columnas>=41 && f_escribe_renglon(item.informacion)">{{ formato(item.c41) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[40]" class="text-right border" v-if="columnas>=41 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[41]" class="text-right border" v-if="columnas>=42 && f_escribe_renglon(item.informacion)">{{ formato(item.c42) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[41]" class="text-right border" v-if="columnas>=42 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[42]" class="text-right border" v-if="columnas>=43 && f_escribe_renglon(item.informacion)">{{ formato(item.c43) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[42]" class="text-right border" v-if="columnas>=43 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[43]" class="text-right border" v-if="columnas>=44 && f_escribe_renglon(item.informacion)">{{ formato(item.c44) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[43]" class="text-right border" v-if="columnas>=44 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[44]" class="text-right border" v-if="columnas>=45 && f_escribe_renglon(item.informacion)">{{ formato(item.c45) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[44]" class="text-right border" v-if="columnas>=45 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[45]" class="text-right border" v-if="columnas>=46 && f_escribe_renglon(item.informacion)">{{ formato(item.c46) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[45]" class="text-right border" v-if="columnas>=46 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[46]" class="text-right border" v-if="columnas>=47 && f_escribe_renglon(item.informacion)">{{ formato(item.c47) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[46]" class="text-right border" v-if="columnas>=47 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[47]" class="text-right border" v-if="columnas>=48 && f_escribe_renglon(item.informacion)">{{ formato(item.c48) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[47]" class="text-right border" v-if="columnas>=48 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[48]" class="text-right border" v-if="columnas>=49 && f_escribe_renglon(item.informacion)">{{ formato(item.c49) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[48]" class="text-right border" v-if="columnas>=49 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[49]" class="text-right border" v-if="columnas>=50 && f_escribe_renglon(item.informacion)">{{ formato(item.c50) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[49]" class="text-right border" v-if="columnas>=50 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[50]" class="text-right border" v-if="columnas>=51 && f_escribe_renglon(item.informacion)">{{ formato(item.c51) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[50]" class="text-right border" v-if="columnas>=51 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[51]" class="text-right border" v-if="columnas>=52 && f_escribe_renglon(item.informacion)">{{ formato(item.c52) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[51]" class="text-right border" v-if="columnas>=52 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[52]" class="text-right border" v-if="columnas>=53 && f_escribe_renglon(item.informacion)">{{ formato(item.c53) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[52]" class="text-right border" v-if="columnas>=53 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[53]" class="text-right border" v-if="columnas>=54 && f_escribe_renglon(item.informacion)">{{ formato(item.c54) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[53]" class="text-right border" v-if="columnas>=54 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[54]" class="text-right border" v-if="columnas>=55 && f_escribe_renglon(item.informacion)">{{ formato(item.c55) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[54]" class="text-right border" v-if="columnas>=55 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[55]" class="text-right border" v-if="columnas>=56 && f_escribe_renglon(item.informacion)">{{ formato(item.c56) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[55]" class="text-right border" v-if="columnas>=56 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[56]" class="text-right border" v-if="columnas>=57 && f_escribe_renglon(item.informacion)">{{ formato(item.c57) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[56]" class="text-right border" v-if="columnas>=57 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[57]" class="text-right border" v-if="columnas>=58 && f_escribe_renglon(item.informacion)">{{ formato(item.c58) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[57]" class="text-right border" v-if="columnas>=58 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[58]" class="text-right border" v-if="columnas>=59 && f_escribe_renglon(item.informacion)">{{ formato(item.c59) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[58]" class="text-right border" v-if="columnas>=59 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[59]" class="text-right border" v-if="columnas>=59 && f_escribe_renglon(item.informacion)">{{ formato(item.c60) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[59]" class="text-right border" v-if="columnas>=60 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[60]" class="text-right border" v-if="columnas>=60 && f_escribe_renglon(item.informacion)">{{ formato(item.c61) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[60]" class="text-right border" v-if="columnas>=61 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[61]" class="text-right border" v-if="columnas>=61 && f_escribe_renglon(item.informacion)">{{ formato(item.c62) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[61]" class="text-right border" v-if="columnas>=62 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[62]" class="text-right border" v-if="columnas>=63 && f_escribe_renglon(item.informacion)">{{ formato(item.c63) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[62]" class="text-right border" v-if="columnas>=63 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[63]" class="text-right border" v-if="columnas>=64 && f_escribe_renglon(item.informacion)">{{ formato(item.c64) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[63]" class="text-right border" v-if="columnas>=64 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[64]" class="text-right border" v-if="columnas>=65 && f_escribe_renglon(item.informacion)">{{ formato(item.c65) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[64]" class="text-right border" v-if="columnas>=65 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[65]" class="text-right border" v-if="columnas>=66 && f_escribe_renglon(item.informacion)">{{ formato(item.c66) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[65]" class="text-right border" v-if="columnas>=66 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[66]" class="text-right border" v-if="columnas>=67 && f_escribe_renglon(item.informacion)">{{ formato(item.c67) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[66]" class="text-right border" v-if="columnas>=67 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="titulo_columna[67]" class="text-right border" v-if="columnas>=68 && f_escribe_renglon(item.informacion)">{{ formato(item.c68) }}</md-table-cell>
			<md-table-cell :md-label="titulo_columna[67]" class="text-right border" v-if="columnas>=68 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="labels.ajustes" class="text-right border" v-if="columnas>0 && f_escribe_renglon(item.informacion)">{{ formato(item.ajustes) }}</md-table-cell>
			<md-table-cell :md-label="labels.ajustes" class="text-right border" v-if="columnas>0 && !f_escribe_renglon(item.informacion)"></md-table-cell>
			<md-table-cell :md-label="labels.consolidado" class="text-right border" v-if="columnas>0 && f_escribe_renglon(item.informacion)">{{ formato(item.total) }}</md-table-cell>
			<md-table-cell :md-label="labels.consolidado" class="text-right border" v-if="columnas>0 && !f_escribe_renglon(item.informacion)"></md-table-cell>

			</md-table-row>
                </md-table>
            </b-container>
            <b-container v-if="!isEmpty && informacion" class="pb-5">
                <md-table v-model="balance" md-card md-fixed-header @md-selected="f_muestra">
			<md-table-toolbar>
				<h1 class="md-title"><b><i>{{f_titulo()}}</i></b> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        <md-button v-if="fgl_excel" @click="excel(3)" class = "md-fab md-primary"><md-icon><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" /></svg>
</md-icon></md-button>
				</h1>
			</md-table-toolbar>
			<md-table-row slot="md-table-row" slot-scope="{ item }"  md-selectable="single" >
				<md-table-cell :md-label="labels.nivel1" class="text-left border">{{ item.nivel_1_desc }}</md-table-cell>
				<md-table-cell :md-label="labels.nivel2" class="text-left border">{{ item.nivel_2_desc }}</md-table-cell>
				<md-table-cell :md-label="labels.nivel3" class="text-left border">{{ item.nivel_3_desc }}</md-table-cell>
				<md-table-cell :md-label="labels.nivel4" class="text-left border">{{ item.nivel_4_desc }}</md-table-cell>
				<md-table-cell :md-label="labels.monto" class="text-right border">{{ formato(item.valor) }}</md-table-cell>
			</md-table-row>
                </md-table>
            </b-container>
            <b-container v-if="isEmpty">
                <b-col md="2" class="mx-auto">
                    <div class="success-svg mb-4" :class="{ active: isEmpty }">
                        <img src="../../assets/imgs/file-2.svg" alt="warning" />
                    </div>
                </b-col>
                <b-col md="12" class="justify-content-around">
                    <span id="message" v-if="isEmpty">{{labels.empty}}</span>
                </b-col>
            </b-container>
        </b-row>

        <md-dialog :md-active.sync="showDialogInformacion">
		<md-dialog-title class="text-center"><h3><b><i>{{labels.informacion}}</i></b></h3></md-dialog-title>
		<md-dialog-content>
			<b-form-group @submit.prevent="editaInfoBalance">
			<b-row><b-col><h6>{{labels.nivel1}}</h6></b-col>
			<b-col cols=7> <md-field> <md-input v-model="formInformacion.nivel_1_desc" type="text" readonly></md-input></md-field></b-col></b-row>
			<b-row><b-col><h6>{{labels.nivel2}}</h6></b-col>
			<b-col cols=7> <md-field> <md-input v-model="formInformacion.nivel_2_desc" type="text" readonly></md-input></md-field></b-col></b-row>
			<b-row><b-col><h6>{{labels.nivel3}}</h6></b-col>
			<b-col cols=7> <md-field> <md-input v-model="formInformacion.nivel_3_desc" type="text" readonly></md-input></md-field></b-col></b-row>
			<b-row><b-col><h6>{{labels.nivel4}}</h6></b-col>
			<b-col cols=7> <md-field> <md-input v-model="formInformacion.nivel_4_desc" type="text" readonly></md-input></md-field></b-col></b-row>
			<b-row><b-col><h6>{{labels.monto}}</h6></b-col>
			<b-col cols=7> <md-field> <md-input v-model="formInformacion.valor" type="text"></md-input></md-field></b-col></b-row>
			</b-form-group>
			<md-dialog-actions>
				<md-button class="md-primary" @click="f_grabaInformacion()">Guardar</md-button>
				<md-button class="md-accent" @click="showDialogInformacion = false">Cerrar</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</div>
</template>
<script>
import Balance from "@/services/BalanceNiif"
import * as XLSX from "exceljs"

export default {
  name: "BalanceNiif",

  data: () => ({
    rolSeleccionado: {},//<-- el seleccionado estará aquí
    myOptions: [],
    rolSelected: '',
    showDialog: false,
    showDialogInformacion: false,
    dialogItem: {},
    balance: [],
    dialogReportes : [],
    codReporte : 0,
    reportes: [],
    searched: [],
    isEmpty: true,
    isLoading: false,
    fgl_excel: true,
    error: false,
    formInformacion: {
	nivel_1: '',
	nivel_1_desc: '',
	nivel_2: '',
	nivel_2_desc: '',
	nivel_3: '',
	nivel_3_desc: '',
	nivel_4: '',
	nivel_4_desc: '',
	valor: '',
	u_modifica: '',
	directo: '',
    },
    idCompania:0,
    moneda : "",
    vigencia:'',
    informacion: false,
    comparativo:false,
    directo:false,
    indirecto:false,
    consolidado:false,
    companias : [],
    companiaUsuario: [],
    dialogCompanias : [],
    fechas : [],
    dialogFechas : [],
    columnas: 0,
    titulo_columna: [],
    empresa: 0,
    generado: false,
    codigoCompanias: "",
    nivel: 1,
    descripcion: "",

  }),
  computed:{
    labels(){
      if (this.$store.state._language == "es-ES") {
	return {
		title: "BALANCE",
		codigo: "Codigo",
		descripcion: "Descripcion",
		saldo_anterior: "Saldo Periodo Anterior",
		saldo_actual: "Saldo Periodo Actual",
		diferencia: "Diferencia",
		monto: "Valor",
		empty: "Sin Seleccion",
		notFoundMessage: "Sin Informacion",
		generar : "Generar",
		ajustes : "Ajustes",
		consolidado : "Consolidado",
		moneda : "Moneda",
		nivel1: "Nivel 1",
		nivel2: "Nivel 2",
		nivel3: "Nivel 3",
		nivel4: "Nivel 4",
		informacion: "Informacion Manual",
		comparativo: "Comparativo",
		directo: "Directo",
		indirecto: "Indirecto",
		informaciond: "Informacion",
		nivel: "Nivel",

		}
       }else {
	return {
		title: "NIIF CHECKING BALANCE",
		codigo: "Code",
		descripcion: "Description",
		saldo_anterior: "Previous Period Balance",
		saldo_actual: "Current Period Balance",
		diferencia: "Difference",
		monto: "Amount",
		compania: "Company",
		empty: "Without Information",
		notFoundMessage: "Without Information",
		generar : "Trigger",
		ajustes : "Settings",
		consolidado : "Consolidated",
		moneda : "Currency",
		nivel1: "Level 1",
		nivel2: "Level 2",
		nivel3: "Level 3",
		nivel4: "Level 4",
		informacion: "Manual Information",
		comparativo: "Comparative",
		directo: "Direct",
		indirecto: "Indirect",
		informaciond: "Information",
		nivel: "Level",
        }
      }
    }
  },
  methods: {
    f_titulo() {
	let titulo = ""
	let tituloEN = ""
	let tituloConsolidado = ""
	titulo = "FLUJO DE EFECTIVO"
	tituloEN = "CASH FLOW "
	if	(this.$store.state._language == "es-ES") {
		tituloConsolidado = titulo
	} else	{
		tituloConsolidado = tituloEN
	}
	if	(this.comparativo) {
		if	(this.$store.state._language == "es-ES") {
			tituloConsolidado = titulo.concat(" COMPARATIVO")
		} else {
			tituloConsolidado = "COMPARATIVE"
			tituloConsolidado = tituloConsolidado.concat(" ",tituloEN)
		}
	}
	if	(this.directo) {
		if	(this.$store.state._language == "es-ES") {
			tituloConsolidado = titulo.concat("-DIRECTO")
		} else {
			tituloConsolidado = tituloEN.concat("-DIRECT")
		}
	}
	if	(this.indirecto) {
		if	(this.$store.state._language == "es-ES") {
			tituloConsolidado = titulo.concat("-INDIRECTO")
		} else {
			tituloConsolidado = tituloEN.concat("-INDIRECT")
		}
	}
	if	(this.informacion) {
		if	(this.$store.state._language == "es-ES") {
			tituloConsolidado = tituloConsolidado.concat(" INFORMACION")
		} else {
			tituloConsolidado = tituloConsolidado.concat(" INFORMATION")
		}
	}
	if	(this.consolidado) {
		if	(this.$store.state._language == "es-ES") {
			tituloConsolidado = tituloConsolidado.concat("(CONSOLIDADO)")
		} else {
			tituloConsolidado = tituloConsolidado.concat("(CONSOLIDATED)")
		}
	}
	tituloConsolidado=tituloConsolidado.concat(" ",
			this.vigencia.toUpperCase());
	if (this.moneda) {
		tituloConsolidado=tituloConsolidado.concat(" ",
			this.labels.moneda," ",this.moneda);
	}
	return tituloConsolidado
    },
    f_columnas() {
	for (let idx=0;idx< this.columnas;idx++) {
		switch	(idx) {
		case 0: this.titulo_columna[idx]  = this.balance[0].e1; break;
		case 1: this.titulo_columna[idx]  = this.balance[0].e2; break;
		case 2: this.titulo_columna[idx]  = this.balance[0].e3; break;
		case 3: this.titulo_columna[idx]  = this.balance[0].e4; break;
		case 4: this.titulo_columna[idx]  = this.balance[0].e5; break;
		case 5: this.titulo_columna[idx]  = this.balance[0].e6; break;
		case 6: this.titulo_columna[idx]  = this.balance[0].e7; break;
		case 7: this.titulo_columna[idx]  = this.balance[0].e8; break;
		case 8: this.titulo_columna[idx]  = this.balance[0].e9; break;
		case 9: this.titulo_columna[idx]  = this.balance[0].e10; break;
		case 10: this.titulo_columna[idx]  = this.balance[0].e11; break;
		case 11: this.titulo_columna[idx]  = this.balance[0].e12; break;
		case 12: this.titulo_columna[idx]  = this.balance[0].e13; break;
		case 13: this.titulo_columna[idx]  = this.balance[0].e14; break;
		case 14: this.titulo_columna[idx]  = this.balance[0].e15; break;
		case 15: this.titulo_columna[idx]  = this.balance[0].e16; break;
		case 16: this.titulo_columna[idx]  = this.balance[0].e17; break;
		case 17: this.titulo_columna[idx]  = this.balance[0].e18; break;
		case 18: this.titulo_columna[idx]  = this.balance[0].e19; break;
		case 19: this.titulo_columna[idx]  = this.balance[0].e20; break;
		case 20: this.titulo_columna[idx]  = this.balance[0].e21; break;
		case 21: this.titulo_columna[idx]  = this.balance[0].e22; break;
		case 22: this.titulo_columna[idx]  = this.balance[0].e23; break;
		case 23: this.titulo_columna[idx]  = this.balance[0].e24; break;
		case 24: this.titulo_columna[idx]  = this.balance[0].e25; break;
		case 25: this.titulo_columna[idx]  = this.balance[0].e26; break;
		case 26: this.titulo_columna[idx]  = this.balance[0].e27; break;
		case 27: this.titulo_columna[idx]  = this.balance[0].e28; break;
		case 28: this.titulo_columna[idx]  = this.balance[0].e29; break;
		case 29: this.titulo_columna[idx]  = this.balance[0].e30; break;
		case 30: this.titulo_columna[idx]  = this.balance[0].e31; break;
		case 31: this.titulo_columna[idx]  = this.balance[0].e32; break;
		case 32: this.titulo_columna[idx]  = this.balance[0].e33; break;
		case 33: this.titulo_columna[idx]  = this.balance[0].e34; break;
		case 34: this.titulo_columna[idx]  = this.balance[0].e35; break;
		case 35: this.titulo_columna[idx]  = this.balance[0].e36; break;
		case 36: this.titulo_columna[idx]  = this.balance[0].e37; break;
		case 37: this.titulo_columna[idx]  = this.balance[0].e38; break;
		case 38: this.titulo_columna[idx]  = this.balance[0].e39; break;
		case 39: this.titulo_columna[idx]  = this.balance[0].e40; break;
		case 40: this.titulo_columna[idx]  = this.balance[0].e41; break;
		case 41: this.titulo_columna[idx]  = this.balance[0].e42; break;
		case 42: this.titulo_columna[idx]  = this.balance[0].e43; break;
		case 43: this.titulo_columna[idx]  = this.balance[0].e44; break;
		case 44: this.titulo_columna[idx]  = this.balance[0].e45; break;
		case 45: this.titulo_columna[idx]  = this.balance[0].e46; break;
		case 46: this.titulo_columna[idx]  = this.balance[0].e47; break;
		case 47: this.titulo_columna[idx]  = this.balance[0].e48; break;
		case 48: this.titulo_columna[idx]  = this.balance[0].e49; break;
		case 49: this.titulo_columna[idx]  = this.balance[0].e50; break;
		case 50: this.titulo_columna[idx]  = this.balance[0].e51; break;
		case 51: this.titulo_columna[idx]  = this.balance[0].e52; break;
		case 52: this.titulo_columna[idx]  = this.balance[0].e53; break;
		case 53: this.titulo_columna[idx]  = this.balance[0].e54; break;
		case 54: this.titulo_columna[idx]  = this.balance[0].e55; break;
		case 55: this.titulo_columna[idx]  = this.balance[0].e56; break;
		case 56: this.titulo_columna[idx]  = this.balance[0].e57; break;
		case 57: this.titulo_columna[idx]  = this.balance[0].e58; break;
		case 58: this.titulo_columna[idx]  = this.balance[0].e59; break;
		case 59: this.titulo_columna[idx]  = this.balance[0].e60; break;
		case 60: this.titulo_columna[idx]  = this.balance[0].e61; break;
		case 61: this.titulo_columna[idx]  = this.balance[0].e62; break;
		case 62: this.titulo_columna[idx]  = this.balance[0].e63; break;
		case 63: this.titulo_columna[idx]  = this.balance[0].e64; break;
		case 64: this.titulo_columna[idx]  = this.balance[0].e65; break;
		case 65: this.titulo_columna[idx]  = this.balance[0].e66; break;
		case 66: this.titulo_columna[idx]  = this.balance[0].e67; break;
		case 67: this.titulo_columna[idx]  = this.balance[0].e68; break;
		case 68: this.titulo_columna[idx]  = this.balance[0].e69; break;
		}
	}
    },
    generar() {
	if	(this.dialogCompanias && this.dialogFechas && this.dialogReportes) {
		this.idCompania=this.dialogCompanias
		this.vigencia=this.dialogFechas
		this.codReporte=this.dialogReportes
		this.isEmpty=true
		this.generado=false
		if	(this.comparativo) {
			Balance.getReporteEfe(this.idCompania,this.vigencia,this.codReporte)
			.then((result) => {
				this.balance = result.data.reporteEfe;
				this.searched = this.balance;
				if (this.balance.length != 0) {
					this.isEmpty = false;
					this.generado=true
				}
			})
			.catch(() => {
				this.error = true;
			});
		}
		if	(this.directo && !this.informacion && this.codReporte) {
			Balance.getReporteEfeDirecto(this.idCompania,this.vigencia,this.codReporte,this.nivel,this.consolidado)
			.then((result) => {
				this.balance = result.data.reporteEfe
				this.searched = this.balance;
				if (this.balance.length != 0) {
					this.isEmpty = false;
					this.generado=true
					this.columnas = this.balance[0].columnas
					this.f_columnas()
				}
			})
			.catch(() => {
				this.error = true;
			});
		}
		if	(this.indirecto && !this.informacion && this.codReporte) {
			Balance.getReporteEfeIndirecto(this.idCompania,this.vigencia,this.codReporte,this.nivel,this.consolidado)
			.then((result) => {
				this.balance = result.data.reporteEfe;
				this.searched = this.balance;
				if (this.balance.length != 0) {
					this.isEmpty = false;
					this.generado=true
					this.columnas = this.balance[0].columnas
					this.f_columnas()
				}
			})
			.catch(() => {
				this.error = true;
			});
		}
		if	((this.directo || this.indirecto) && this.informacion && this.codReporte) {
			Balance.getReporteInformacion(this.idCompania,this.vigencia,this.codReporte,this.directo,this.$store.state.userId)
			.then((result) => {
				this.balance = result.data.informacionManual;
				this.searched = this.balance;
				if (this.balance.length != 0) {
					this.isEmpty = false;
					this.generado=true
				}
			})
			.catch(() => {
				this.error = true;
			});
		}
	}
    },
    f_escribe_renglon(v_informacion) {
	if	(v_informacion === "E") return false;
	return true;
    },
    uncheck(opcion) {
	var checkbox0 = document.getElementById("informacion");
	var checkbox1 = document.getElementById("comparativo");
	var checkbox2 = document.getElementById("directo");
	var checkbox3 = document.getElementById("indirecto");
	var checkboxn1 = document.getElementById("nivel_1");
	var checkboxn2 = document.getElementById("nivel_2");
	var checkboxn3 = document.getElementById("nivel_3");
	var checkboxn4 = document.getElementById("nivel_4");
	var checkbox4 = document.getElementById("consolidado");

	if	(opcion == 4) {
		if	(!this.directo && !this.indirecto) {
			checkbox4.checked = false;
			this.isEmpty=true
			this.generado=false
			return
		} else {
			checkbox0.checked = false
			if	(this.consolidado) {
				checkbox4.checked = false
				this.consolidado=false
			} else {
				checkbox4.chequed = true
				this.consolidado=true
			}
			if	(this.nivel == 0) {
				this.nivel = 1
				checkboxn1.checked=true
			}
			this.isEmpty=true
			this.generado=false
			return
		}
	} else {
		checkbox0.checked = false
		checkbox1.checked = false
		checkbox2.checked = false
		checkbox3.checked = false
		checkboxn1.checked = false;
		checkboxn2.checked = false;
		checkboxn3.checked = false;
		checkboxn4.checked = false;
		checkbox4.checked = false;
	}

	this.consolidado=false
	this.informacion=false
	this.comparativo=false
	this.directo=false
	this.indirecto=false
	if	(opcion == 1) {
                checkbox1.checked = true;
		this.comparativo=true
	}
	if	(opcion == 2) {
                checkbox2.checked = true;
                checkboxn1.checked = true;
		this.directo=true
		this.nivel=1
	}
	if	(opcion == 3) {
                checkbox3.checked = true;
                checkboxn1.checked = true;
		this.indirecto=true
		this.nivel=1
	}
	if	(opcion == 4) {
                checkbox4.checked = true;
		this.consolidado=true
	}
	this.isEmpty=true
	this.generado=false
    },
    seleccionaCompania() {
	this.isEmpty=true;
        let buscaMoneda = this.companias.filter((item) =>
                item.id==this.dialogCompanias)
	this.moneda = buscaMoneda[0].acronimo
    },
    uncheck_nivel(opcion) {
	var checkbox0 = document.getElementById("informacion");
	var checkbox1 = document.getElementById("nivel_1");
	var checkbox2 = document.getElementById("nivel_2");
	var checkbox3 = document.getElementById("nivel_3");
	var checkbox4 = document.getElementById("nivel_4");
	var checkbox5 = document.getElementById("consolidado");
	checkbox0.checked = false;
	checkbox1.checked = false;
	checkbox2.checked = false;
	checkbox3.checked = false;
	checkbox4.checked = false;
	this.informacion = false
	if	(this.comparativo) {
		this.consolidado = false
		checkbox5.checked = false;
		return
	}
	if	(opcion == 0) {
		checkbox0.checked = true;
		this.informacion = true
		this.comparativo = false
		this.consolidado = false
		checkbox5.checked = false;
	}
	if	(opcion == 1) {
		checkbox1.checked = true;
	}
	if	(opcion == 2) {
		checkbox2.checked = true;
	}
	if	(opcion == 3) {
		checkbox3.checked = true;
	}
	if	(opcion == 4) {
		checkbox4.checked = true;
	}
	this.nivel=opcion
	this.isEmpty=true
	this.generado=false
    },
    formato(numero) {
	return  new Intl.NumberFormat('en-US',{ maximumFractionDigits: 0}).format(numero)
    },

	excel(opcion) {
		let datos = {}
		if	(opcion === 1) {
			datos.opcion = 1
			datos.idCompania = this.idCompania
			const v_compania = this.companias.find((compania) => compania.id === this.idCompania)
			datos.compania = v_compania.nombre
			datos.vigencia = this.vigencia
			datos.titulo = this.f_titulo()
			datos.columnas = [
				this.labels.saldo_anterior,
				this.labels.saldo_actual,
				this.labels.diferencia,
			]
			datos.informacion = this.balance
			datos.userId = this.$store.state.userId
			datos.userName = this.$store.state.userName
			this.hojaExcelReporteEfe(datos)
		}
		if	(opcion === 2) {
			if	(this.directo) {
				datos.opcion = 1
			} else	{
				datos.opcion = 2
			}
			datos.idCompania = this.idCompania
			const v_compania = this.companias.find((compania) => compania.id === this.idCompania)
			datos.compania = v_compania.nombre
			datos.vigencia = this.vigencia
			datos.titulo = this.f_titulo()
			datos.columnas = [
				this.labels.descripcion,
				this.labels.monto
			]
			datos.informacion = this.balance
			datos.userId = this.$store.state.userId
			datos.userName = this.$store.state.userName
			this.hojaExcelReporteEfeDirecto(datos)
		}
		if	(opcion === 3) {
			if	(this.directo) {
				datos.opcion = 1
			} else	{
				datos.opcion = 2
			}
			datos.idCompania = this.idCompania
			const v_compania = this.companias.find((compania) => compania.id === this.idCompania)
			datos.compania = v_compania.nombre
			datos.vigencia = this.vigencia
			datos.titulo = this.f_titulo()
			datos.columnas = [
				this.labels.nivel1,
				this.labels.nivel2,
				this.labels.nivel3,
				this.labels.nivel4,
				this.labels.monto
			]
			datos.informacion = this.balance
			datos.userId = this.$store.state.userId
			datos.userName = this.$store.state.userName
			this.hojaExcelReporteEfeInformacion(datos)
			.then((result) => {
				if	(result.status !== 200) {
					this.$toast.error("Problema al generar Excel")
					return
				}
				this.rutaExcel = result.data.rutaExcel
				this.$toast.success("Excel "+this.rutaExcel+" Generado")
			})
			.catch(() => {
				this.error = true
			});
		}
		if	(opcion === 4) {
			if	(this.directo) {
				datos.opcion = 1
			} else	{
				datos.opcion = 2
			}
			datos.idCompania = this.idCompania
			const v_compania = this.companias.find((compania) => compania.id === this.idCompania)
			datos.compania = v_compania.nombre
			datos.vigencia = this.vigencia
			datos.titulo = this.f_titulo()
			datos.columnas = [
				this.labels.nivel1,
				this.labels.nivel2,
				this.labels.nivel3,
				this.labels.nivel4,
				this.labels.monto
			]
			datos.informacion = this.balance
			datos.userId = this.$store.state.userId
			datos.userName = this.$store.state.userName
			this.hojaExcelReporteEfeInformacion(datos)
			.then((result) => {
				if	(result.status !== 200) {
					this.$toast.error("Problema al generar Excel")
					return
				}
				this.rutaExcel = result.data.rutaExcel
				this.$toast.success("Excel "+this.rutaExcel+" Generado")
			})
			.catch(() => {
				this.error = true
			});
		}
	},

	async hojaExcelReporteEfe (datos) {
		let createdAt1 = new Date()
		let anio = createdAt1.getFullYear()
		let mes = createdAt1.getMonth()+1
		let dia = createdAt1.getDate()
		let createdAt = anio+"-"+mes+"-"+dia

		let compania = datos.compania
		let vigencia = datos.vigencia
		let titulo = datos.titulo
		let columnas = datos.columnas
		let userName = datos.userName
		const filas = datos.informacion

		const {Workbook} = XLSX
		const workbook = new Workbook()
		const worksheet = workbook.addWorksheet("ComparativoEFE")

		const ENCABEZADO1 = [
			titulo
		]
		worksheet.addRow(ENCABEZADO1)
		const ENCABEZADO2 = [
			"Vigencia "+vigencia,
		]
		worksheet.addRow(ENCABEZADO2)
		const ENCABEZADO3 = [
			"Creado "+createdAt+" por "+userName,
		]
		worksheet.addRow(ENCABEZADO3)
		const ENCABEZADO4 = [
			"Compañia "+compania,
		]
		worksheet.addRow(ENCABEZADO4)
		const ENCABEZADO5 = [
		]
		worksheet.addRow(ENCABEZADO5)
		
		const HEADER_ROW = [
			'DESCRIPCION/NIIF (IFRS)',
			columnas[0],
			columnas[1],
			columnas[2]
		]
		worksheet.addRow(HEADER_ROW)
		for	(let fila = 1;fila<7;fila++) {
			worksheet.getRow(fila).font = {
				name: "Arial Black",
				family: 2,
				size: 14,
				bold: true,
				italic: true,
			}
		}
		
		for	(let columna=1;columna<3;columna++) {
			const v_columna=String.fromCharCode(columna+65)+"6"
			worksheet.getCell(v_columna).alignment = {
				horizontal : "center",
			}
		}

		var n_fila = 7
		filas.forEach((fila) => {
			if	(fila.informacion === "E") {
				const v_salto = [ "" ]
				worksheet.addRow(v_salto)
				n_fila++
			}
			const v_fila = [
				fila.descripcion,
				(fila.anterior === null) ? fila.anterior : parseFloat(fila.anterior),
				(fila.actual === null) ? fila.actual : parseFloat(fila.actual),
				(fila.diferencia === null) ? fila.diferencia : parseFloat(fila.diferencia),
			]
			worksheet.addRow(v_fila)
			for	(let columna=0;columna<3;columna++) {
				const v_columna=String.fromCharCode(columna+65)+n_fila.toString()
				if	(columna === 0) {
					worksheet.getCell(v_columna).font = {
						name: "Arial Black",
						family: 2,
						size: 13,
						bold: (fila.informacion === "E" || fila.informacion === "T") ? true : false,
						italic: (fila.informacion === "E" || fila.informacion === "T") ? true : false,
					}
					worksheet.getCell(v_columna).alignment = {
						horizontal : (fila.informacion === "I" || (fila.informacion === 'T' && fila.segundo_nivel > 0 && fila.segundo_nivel<999) || (fila.informacion = 'E' && fila.segundo_nivel>0 && fila.segundo_nivel < 999)) ? 'right' : 'left',
					}
				} else {
					worksheet.getCell(v_columna).font = {
						size: 13,
					}
					worksheet.getCell(v_columna).numFmt = "#,0"
				}
			}
			n_fila++
			
		})
		
		const file = await workbook.xlsx.writeBuffer()
		const downloadLink = document.createElement("a")
		downloadLink.download = "ComparativoEFE"+vigencia+".xlsx"
		downloadLink.href = window.URL.createObjectURL(
			new Blob([file], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			})
		)

		downloadLink.style.display = "none";

		document.body.appendChild(downloadLink);
		downloadLink.click();

		downloadLink.remove();
		
	},

	async hojaExcelReporteEfeDirecto (datos) {
		let createdAt1 = new Date()
		let anio = createdAt1.getFullYear()
		let mes = createdAt1.getMonth()+1
		let dia = createdAt1.getDate()
		let createdAt = anio+"-"+mes+"-"+dia

		let opcion = datos.opcion
		let compania = datos.compania
		let vigencia = datos.vigencia
		let titulo = datos.titulo
		let columnas = datos.columnas
		let userName = datos.userName
		const filas = datos.informacion

		const {Workbook} = XLSX
		const workbook = new Workbook()
		let worksheet = ''
		if	(opcion === 1) {
			worksheet = workbook.addWorksheet("EFEDirecto")
		} else {
			worksheet = workbook.addWorksheet("EFEIndirecto")
		}

		const ENCABEZADO1 = [
			titulo
		]
		worksheet.addRow(ENCABEZADO1)
		const ENCABEZADO2 = [
			"Vigencia "+vigencia,
		]
		worksheet.addRow(ENCABEZADO2)
		const ENCABEZADO3 = [
			"Creado "+createdAt+" por "+userName,
		]
		worksheet.addRow(ENCABEZADO3)
		const ENCABEZADO4 = [
			"Compañia "+compania,
		]
		worksheet.addRow(ENCABEZADO4)
		const ENCABEZADO5 = [
		]
		worksheet.addRow(ENCABEZADO5)
		
		const HEADER_ROW = [
			columnas[0],
			columnas[1]
		]
		worksheet.addRow(HEADER_ROW)
		for	(let fila = 1;fila<7;fila++) {
			worksheet.getRow(fila).font = {
				name: "Arial Black",
				family: 2,
				size: 14,
				bold: true,
				italic: true,
			}
		}
		
		for	(let columna=1;columna<2;columna++) {
			const v_columna=String.fromCharCode(columna+65)+"6"
			worksheet.getCell(v_columna).alignment = {
				horizontal : "center",
			}
		}

		var n_fila = 7
		const v_salto = [ "" ]
		worksheet.addRow(v_salto)
		n_fila++
		filas.forEach((fila) => {
			const v_fila = [
				fila.descripcion,
				(fila.valor === null) ? fila.valor : parseFloat(fila.valor),
			]
			worksheet.addRow(v_fila)
			for	(let columna=0;columna<2;columna++) {
				const v_columna=String.fromCharCode(columna+65)+n_fila.toString()
				if	(columna === 0) {
					worksheet.getCell(v_columna).font = {
						name: "Arial Black",
						family: 2,
						size: 13,
						bold: (fila.tipo === 'E' || fila.tipo === 'T') ? true : false,
						italic: (fila.tipo === 'E' || fila.tipo === 'T') ? true : false,
					}
				}
				if	(columna === 1) {
					worksheet.getCell(v_columna).font = {
						name: "Arial Black",
						family: 2,
						size: 13,
						bold: false,
						italic: false,
					}
					worksheet.getCell(v_columna).numFmt = "#,0"
				}
			}
			n_fila++
			
		})
		
		const file = await workbook.xlsx.writeBuffer()
		const downloadLink = document.createElement("a")
		if	(opcion === 1) {
			downloadLink.download = "EFEDirecto"+vigencia+".xlsx"
		} else {
			downloadLink.download = "EFEIndirecto"+vigencia+".xlsx"
		}
		downloadLink.href = window.URL.createObjectURL(
			new Blob([file], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			})
		)

		downloadLink.style.display = "none";

		document.body.appendChild(downloadLink);
		downloadLink.click();

		downloadLink.remove();
		
	},
	async hojaExcelReporteEfeInformacion (datos) {
		let createdAt1 = new Date()
		let anio = createdAt1.getFullYear()
		let mes = createdAt1.getMonth()+1
		let dia = createdAt1.getDate()
		let createdAt = anio+"-"+mes+"-"+dia

		let opcion = datos.opcion
		let compania = datos.compania
		let vigencia = datos.vigencia
		let titulo = datos.titulo
		let columnas = datos.columnas
		let userName = datos.userName
		const filas = datos.informacion

		const {Workbook} = XLSX
		const workbook = new Workbook()
		let worksheet = ''
		if	(opcion === 1) {
			worksheet = workbook.addWorksheet("DirectoInformacion")
		} else {
			worksheet = workbook.addWorksheet("IndirectoInformacion")
		}

		const ENCABEZADO1 = [
			titulo
		]
		worksheet.addRow(ENCABEZADO1)
		const ENCABEZADO2 = [
			"Vigencia "+vigencia,
		]
		worksheet.addRow(ENCABEZADO2)
		const ENCABEZADO3 = [
			"Creado "+createdAt+" por "+userName,
		]
		worksheet.addRow(ENCABEZADO3)
		const ENCABEZADO4 = [
			"Compañia "+compania,
		]
		worksheet.addRow(ENCABEZADO4)
		const ENCABEZADO5 = [
		]
		worksheet.addRow(ENCABEZADO5)
		
		const HEADER_ROW = [
			columnas[0],
			columnas[1],
			columnas[2],
			columnas[3],
			columnas[4],
		]
		worksheet.addRow(HEADER_ROW)
		for	(let fila = 1;fila<7;fila++) {
			worksheet.getRow(fila).font = {
				name: "Arial Black",
				family: 2,
				size: 14,
				bold: true,
				italic: true,
			}
		}
		
		for	(let columna=1;columna<5;columna++) {
			const v_columna=String.fromCharCode(columna+65)+"6"
			worksheet.getCell(v_columna).alignment = {
				horizontal : "center",
			}
		}

		var n_fila = 7
		const v_salto = [ "" ]
		worksheet.addRow(v_salto)
		n_fila++
		filas.forEach((fila) => {
			const v_fila = [
				fila.nivel_1_desc,
				fila.nivel_2_desc,
				fila.nivel_3_desc,
				fila.nivel_4_desc,
				(fila.valor === null) ? fila.valor : parseFloat(fila.valor),
			]
			worksheet.addRow(v_fila)
			for	(let columna=0;columna<5;columna++) {
				const v_columna=String.fromCharCode(columna+65)+n_fila.toString()
				if	(columna < 4) {
					worksheet.getCell(v_columna).font = {
						name: "Arial Black",
						family: 2,
						size: 13,
						bold: false,
						italic: true
					}
				}
				if	(columna === 4) {
					worksheet.getCell(v_columna).font = {
						name: "Arial Black",
						family: 2,
						size: 13,
						bold: false,
						italic: false,
					}
					worksheet.getCell(v_columna).numFmt = "#,0"
				}
			}
			n_fila++
			
		})
		
		const file = await workbook.xlsx.writeBuffer()
		const downloadLink = document.createElement("a")
		if	(opcion === 1) {
			downloadLink.download = "DirectoInformacion"+vigencia+".xlsx"
		} else {
			downloadLink.download = "IndirectoInformacion"+vigencia+".xlsx"
		}
		downloadLink.href = window.URL.createObjectURL(
			new Blob([file], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			})
		)

		downloadLink.style.display = "none";

		document.body.appendChild(downloadLink);
		downloadLink.click();

		downloadLink.remove();
		
	},

	f_muestra(r_informacion) {
		this.showDialogInformacion=true
		this.formInformacion = r_informacion
	},
	f_grabaInformacion() {
		this.formInformacion.u_modifica=this.$store.state.userId
		this.formInformacion.directo=this.directo
		this.showDialogInformacion=false
		Balance.grabaInformacion(this.idCompania,this.vigencia,this.codReporte,this.formInformacion)
		.then((result) => {
			if	(result.status == 200) {
				this.$toast.error("Informacion Grabada")
			} else	this.$toast.error("Problema al grabar")
		})
		.catch(() => {
			this.error = true;
		});
	}
  },
  mounted() {
    this.isLoading = true;
	Balance.leaCompaniaUsuario()
	.then ((result) => {
		this.companiaUsuario = result.data.companiaUsuario
	})

    Balance.getCompanias(this.comparativo)
    .then ((result) => {
	this.companias = result.data.companias
	this.dialogCompanias = []
	this.codigoCompanias = "{"
	let primero = true
	let v_companias = []
	result.data.companias.forEach((element) => {
		const v_usuario = this.companiaUsuario.find((r_usuario) => 
			r_usuario.id_usuario == this.$store.state.userId &&
			r_usuario.id_compania == element.id)
		if	(this.$store.state.userRole==1 || v_usuario) {
			v_companias.push(element)
		}
	})
	this.companias=v_companias
	result.data.companias.forEach(element => {
		this.dialogCompanias.push(element.id);
		if	(!primero) {
			this.codigoCompanias=this.codigoCompanias+","
		}
		primero=false;
		this.codigoCompanias=this.codigoCompanias+"\"id"+element.id+"\":"+
			"\""+element.codigo+"\""
	})
	this.codigoCompanias=this.codigoCompanias+"}"
	this.codigoCompanias=JSON.parse(this.codigoCompanias)
    })
    .catch(() => {
	this.error = true
    });
    Balance.getFechas()
    .then ((result) => {
	this.fechas = result.data.fechas
	this.dialogFechas = []
	result.data.fechas.forEach(element => {
		this.dialogFechas.push(element.fecha);
	})
    })
    .catch(() => {
	this.error = true
    });
	Balance.getReportes()
	.then ((result) => {
		this.reportes = result.data.reportes.filter((reporte) => reporte.principal == 1)
	})
	.catch(() => {
		this.error = true
	});
    this.isLoading = true;
  },
};
</script>

<style src="../main.css"></style>
