import axios from 'axios'
import store from '@/store'
export default () => {
  return axios.create({
    // baseURL: `http://localhost:7280/api/`,
    baseURL: `https://dw.complementservices.com/api/`,
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  })
}
