<template>
	<div class="container">
		<b-row>
			<b-container>
				<h2 class="text-center py-1"> {{labels.title}} </h2>
			</b-container>
		</b-row>
		<b-row class="py-2">
			<b-container v-if="!isEmpty" class="pb-5">
				<md-table v-model="listCompanias" md-card md-fixed-header @md-selected="f_muestra">
					<md-table-toolbar>
						<h1 class="md-title"><b><i>{{labels.titulo}}</i></b></h1>
					</md-table-toolbar>
					<md-table-row slot="md-table-row" slot-scope="{ item }"  md-selectable="single" >
						<md-table-cell :md-label="labels.nombreCompania" class="text-left border">{{ item.nombre }}</md-table-cell>
						<md-table-cell :md-label="labels.acronym" class="text-left border">{{ item.acronym }}</md-table-cell>
						<md-table-cell :md-label="labels.codigo" class="text-left border">{{ item.codigo }}</md-table-cell>
					</md-table-row>
				</md-table>
			</b-container>
			<md-dialog :md-active.sync="showUsuarios">
				<md-dialog-title class="text-center">
					<h3><b><i>{{f2_titulo()}}</i></b></h3>
					<md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="f_usuario()">
						<md-icon>add</md-icon>
					</md-button>
				</md-dialog-title>
				<md-dialog-content>
					<md-table v-model="listCompaniaUsuario" md-card md-fixed-header @md-selected="f_retira">
						<md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="single">
							<md-table-cell :md-label="labels.nombreUsuario" class="text-left border">{{f_nombre_usuario(item.id_usuario)}}</md-table-cell>
							<md-table-cell :md-label="labels.email" class="text-left border">{{f_email(item.id_usuario)}}</md-table-cell>
						</md-table-row>
					</md-table>
					<md-dialog-actions>
						<md-button class="md-accent" @click="showUsuarios = false">Cerrar</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
			<md-dialog :md-active.sync="showEditUsuario">
				<md-dialog-title class="text-center">
					<h3><b><i>{{f3_titulo()}}</i></b></h3>
				</md-dialog-title>
				<md-dialog-content class="mt-3">
					<b-row>
						<b-col cols="3" class="text-left">
							<h5>Usuario:</h5>
						</b-col>
						<b-col cols="9" lg="20" class="text-left">
							<div class="md-layout md-gutter">
								<div class="md-layout-item">
									<select multiple v-model="arrUsuarios">
										<option v-for="usr in listUsers1" :value="usr.id" :key="usr.id">{{usr.name}}</option>
									</select>
								</div>
							</div>
						</b-col>
					</b-row>
				</md-dialog-content>
				<md-dialog-actions>
					<md-button class="md-accent" @click="f_graba_usuarios()">Grabar</md-button>
					<md-button class="md-accent" @click="showEditUsuario = false">Cerrar</md-button>
				</md-dialog-actions>
			</md-dialog>
		</b-row>
	</div>
</template>
<script>

    import Company from '@/services/Company'
    import Users from '@/services/User'

    export default {
        name : "Manage",

data: () => ({
	isEmpty: false,
	isLoading: false,
	listCompanias: [],
	listUsers: [],
	listUsers1: [],
	listCompaniaUsuario: [],
	arrUsuarios: [],
	showUsuarios: false,
	showEditUsuario: false,
	itemSelected : {},
	titulo_2 : '',
	titulo_3 : '',
	id_compania: ''

}),
computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "Compañías por Usuario",
                        nombreCompania: "Compañía",
			acronym : "Acronimo",
			codigo : "Codigo",
                        nombreUsuario: "Usuario",
                        email: "Email",
                    }
                }else{
                    return {
                        title: "Users by Company",
                        create: "Create",
                        nombreCompania: "Company",
			acronym : "Acronim",
			codigo : "Code",
                        nombreUsuario: "User",
                        email: "Email",
                    }
                }
            }
},
methods: {
	f_nombre_usuario(id_usuario) {
		const v_usuario = this.listUsers.find((usuario)  => usuario.id == id_usuario)
		return v_usuario.name
	},
	f_email(id_usuario) {
		const v_usuario = this.listUsers.find((usuario)  => usuario.id == id_usuario)
		return v_usuario.email
	},
	f_graba_usuarios() {
		const data = {
			id_compania: this.id_compania,
			selUsuarios: this.arrUsuarios,
		}
		Company.grabaCompaniaUsuario(data)
		.then(() => {
			this.$toast.success("Informacion de Usuarios. Grabada")
		})
		.catch(() => {
			this.$toast.Error("Informacion de Usuarios. No Grabada")
		})
		this.showEditUsuario=false
		this.showUsuarios=false
	},
	f2_titulo() {
		return this.titulo_2
	},
	f3_titulo() {
		return this.titulo_3
	},
	f_usuario() {
		this.arrUsuarios = []
		this.listUsers1 = []
		this.listUsers.forEach((usuario) => {
			let v_usuario = this.listCompaniaUsuario.find((r_usuario) => r_usuario.id_usuario == usuario.id)
			if	(v_usuario) {
				v_usuario = usuario
			} else {
				this.listUsers1.push(usuario)
			}
		})
		if	(this.listUsers1.length>0) {
			this.showEditUsuario=true
		}
	},
	closeModal(value){
		this.showDialog = value;
		this.showDialogEdit = value;
		this.showDialogInfo = value;
	},
	f_muestra(r_compania) {
		Company.leaUsuarios(r_compania.id)
		.then((result) => {
			this.listCompaniaUsuario = result.data.listCompaniaUsuario
		})
		.catch(() => {
			this.$toast.error("Error Al leer Informacion de Usuarios")
			return
		})
		this.titulo_2="PERMISOS PARA "+
			r_compania.nombre
		this.titulo_3=r_compania.nombre
		this.id_compania = r_compania.id
		this.showUsuarios=true
	},
	f_retira(r_usuario) {
		const mensaje = "Desea retirar a "+
			this.f_nombre_usuario(r_usuario.id_usuario)
		if	(confirm(mensaje) == true) {
			Company.retiraUsuario(r_usuario)
			.then(() => {
				this.$toast.success("Usuario "+
					this.f_nombre_usuario(r_usuario.id_usuario)+" Retirado")
			})
			.catch(() => {
				this.$toast.error("Error Al leer Informacion de Usuarios")
				return
			})
			this.showEditUsuario=false
			this.showUsuarios=false
		}
	},
	
},
mounted(){

            Company.getAllCompanies()
            .then((result) => {
                // console.log(result.data.companies)
                this.listCompanias = result.data.companies
            })
            .catch((error) => {
                console.log(error)
            })

            Users.getAllUsers()
            .then((result) => {
                this.listUsers = result.data.users.filter((usuario) => usuario.id_rol > 1 && usuario.state == 1)
            })
            .catch((error) => {
                console.log(error)
            })
        }

    }
</script>
<style src="../main.css"></style>
