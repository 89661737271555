<template>
<div class="container">

<b-row>
	<b-container>
		<h3 class="text-center py-2">
			<b><i>{{f_titulo()}}</i></b>
		</h3>
		<h5 class="text-center py-2">
			<label>Reporte</label>
			<select v-model="dialogReportes" v-on:click="consultar()">
				<option v-for="(item, idx) in reportes" :key="'codigo_'+idx" :value="item.codigo">
					{{item.codigo}}-{{item.nombre}}
				</option>
			</select> &nbsp;&nbsp;
			<label>Vigencia</label>
			<select v-model="dialogFechas" v-on:click="consultar()">
				<option v-for="(item, idx) in fechas" :key="'fecha-'+idx" :value="item.fecha">
					{{item.fecha}}
				</option>
			</select> &nbsp;&nbsp;
			<label>Compañia</label>
			<select v-model="dialogCompanias" v-on:click="seleccionaCompania()" >
				<option v-for="(item, idx) in companias" :key="'compania-'+idx" :value="item.id">
					{{item.acronym+" "+item.nombre}}
				</option>
			</select> &nbsp;&nbsp;
			<b-button v-if="dialogFechas && dialogCompanias" variant="outline-primary" @click="crear">
				<h4><b><i>{{labels.crear}}</i></b></h4>
			</b-button>
		</h5>
	</b-container>
	<b-container v-if="dialogFechas && dialogCompanias && dialogReportes && fgl_consultar" class="pb-5">

		<md-table v-model="ajustesTopside" md-card md-fixed-header @md-selected="f_muestra">
			<md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="single">
				<md-table-cell :md-label="labels.secuencia" class="text-left border">{{item.secuencia}}</md-table-cell>
				<md-table-cell :md-label="labels.cuenta" class="text-left border">{{item.cuenta}}</md-table-cell>
				<md-table-cell :md-label="labels.descripcion" class="text-left border">{{item.descripcion}}</md-table-cell>
				<md-table-cell :md-label="labels.niif" class="text-left border">{{f_niifCuenta(item.cuenta)}}</md-table-cell>
				<md-table-cell v-if="reclasifica" :md-label="labels.niif1" class="text-left border">{{f_niif(item.niif1)}}</md-table-cell>
				<md-table-cell :md-label="labels.funcional1" class="text-left border">{{item.ajuste_funcional1}}</md-table-cell>
				<md-table-cell :md-label="labels.promedio1" class="text-left border">{{item.ajuste_promedio1}}</md-table-cell>
				<md-table-cell :md-label="labels.interna1" class="text-left border">{{item.ajuste_interna1}}</md-table-cell>
				<md-table-cell :md-label="labels.funcional2" class="text-left border">{{item.ajuste_funcional2}}</md-table-cell>
				<md-table-cell :md-label="labels.promedio2" class="text-left border">{{item.ajuste_promedio2}}</md-table-cell>
				<md-table-cell :md-label="labels.interna2" class="text-left border">{{item.ajuste_interna2}}</md-table-cell>
			</md-table-row>
		</md-table>
	</b-container>
	<b-container v-if="showDialogEdit" class="pb-5">

		<b-row><h4><b-col cols="4" class="border"><b><i>{{labels.cuenta}}</i></b></b-col></h4>
		<b-col cols="10" class="border">
		<select v-model="v_id" class="form-control" v-on:click="f_datos_cuenta(v_id)">
		<option v-for="cuenta in cuentas" :value="cuenta.id" :key="cuenta.id">{{ cuenta.cuenta }} {{ cuenta.descripcion }}[{{f_niifCuenta(cuenta.cuenta)}}]</option>
		</select>
		</b-col></b-row>

		<b-row><b-col cols="4" class="border"><h4><b><i>{{labels.funcional1}}</i></b></h4></b-col>
		<b-col cols="4" class="border"><h4><b><i>{{labels.promedio1}}</i></b></h4></b-col>
		<b-col cols="4" class="border"><h4><b><i>{{labels.interna1}}</i></b></h4></b-col></b-row>
		<b-row>
		<b-col cols="4" class="border" >
			<md-field> <md-input v-model="v_ajuste_funcional1" type="text" ></md-input> </md-field>
		</b-col>
		<b-col cols="4" class="border" >
			<md-field> <md-input v-model="v_ajuste_promedio1" type="text"></md-input> </md-field>
		</b-col>
		<b-col cols="4" class="border" >
			<md-field> <md-input v-model="v_ajuste_interna1" type="text"></md-input> </md-field>
		</b-col>
		</b-row>

		<b-row><b-col cols="4" class="border"><h4><b><i>{{labels.funcional2}}</i></b></h4></b-col>
		<b-col cols="4" class="border"><h4><b><i>{{labels.promedio2}}</i></b></h4></b-col>
		<b-col cols="4" class="border"><h4><b><i>{{labels.interna2}}</i></b></h4></b-col></b-row>

		<b-row>
		<b-col cols="4" class="border" >
			<md-field> <md-input v-model="v_ajuste_funcional2" type="text"></md-input> </md-field>
		</b-col>
		<b-col cols="4" class="border" >
			<md-field> <md-input v-model="v_ajuste_promedio2" type="text"></md-input> </md-field>
		</b-col>
		<b-col cols="4" class="border" >
			<md-field> <md-input v-model="v_ajuste_interna2" type="text"></md-input> </md-field>
		</b-col>
		</b-row>
		<b-row><h4><b-col cols="4" class="border"><b><i>{{labels.niif}}</i></b></b-col></h4>
		<b-col cols="2" class="text-left border"><h4><b>{{v_niif}}</b></h4></b-col>
		<b-col cols="8" class="text-left border"><h4><b>{{f_niif(v_niif)}}</b></h4></b-col></b-row>
		<b-row v-if="reclasifica"><h4><b-col cols="6" class="border"><b><i>{{labels.niif1}}</i></b></b-col></h4>
		<b-col cols="8" class="border">
		<select v-model="v_niif1" class="form-control" >
		<option v-for="niif in niif" :value="niif.niif" :key="niif.id"><b>{{ niif.niif}}-{{ niif.nameNiif }}</b></option>
		</select>
		</b-col></b-row>

		<md-button class="md-primary" @click="grabeAjusteTopside()"><h4><b>Guardar</b></h4></md-button>
		<md-button class="md-accent" @click="showDialogEdit = false"><h4><b>Cerrar</b></h4></md-button>
	</b-container>
	<md-dialog :md-active.sync="showDialogTopside">
		<md-dialog-title class="text-center"><b><i>{{f_titulo()}}</i></b></md-dialog-title>
		<md-dialog-content>

			<b-form-group @submit.prevent="editaInfoBalance">
			<b-row><b-col cols="3" class="border"><h4><b><i>{{labels.cuenta}}</i></b></h4></b-col>
			<b-col cols="3" class="border"><h4>{{formTopside.cuenta}}</h4></b-col>
			<b-col cols="6" class="border"><h4>{{formTopside.descripcion}}</h4></b-col> </b-row>

			<b-row><b-col cols="4" class="border"><h4><b><i>{{labels.funcional1}}</i></b></h4></b-col>
			<b-col cols="4" class="border"><h4><b><i>{{labels.promedio1}}</i></b></h4></b-col>
			<b-col cols="4" class="border"><h4><b><i>{{labels.interna1}}</i></b></h4></b-col></b-row>
			<b-row><b-col cols="4" class="border" ><h4>{{formTopside.ajuste_funcional1}}</h4></b-col>
			<b-col cols="4" class="border" ><h4>{{formTopside.ajuste_promedio1}}</h4></b-col>
			<b-col cols="4" class="border" ><h4>{{formTopside.ajuste_interna1}}</h4></b-col> </b-row>

			<b-row><b-col cols="4" class="border"><h4><b><i>{{labels.funcional2}}</i></b></h4></b-col>
			<b-col cols="4" class="border"><h4><b><i>{{labels.promedio2}}</i></b></h4></b-col>
			<b-col cols="4" class="border"><h4><b><i>{{labels.interna2}}</i></b></h4></b-col></b-row>
			<b-row><b-col cols="4" class="border" ><h4>{{formTopside.ajuste_funcional2}}</h4></b-col>
			<b-col cols="4" class="border" ><h4>{{formTopside.ajuste_promedio2}}</h4></b-col>
			<b-col cols="4" class="border" ><h4>{{formTopside.ajuste_interna2}}</h4></b-col> </b-row>

			</b-form-group>
			<md-dialog-actions>
				<md-button class="md-primary" @click="borraAjusteTopside(formTopside.secuencia)">Borrar</md-button>
				<md-button class="md-accent" @click="showDialogTopside = false">Cerrar</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</b-row>
</div>
</template>
<script>
import Balance from "@/services/BalanceNiif"
import PlanConsolidacion from "@/services/PlanConsolidacion"
const ordenefechas = (arreglo = undefined) => {
	return [...arreglo].sort((a,b)=>a-b)
}

export default {
  name: "BalanceNiif",

  data: () => ({
	rolSeleccionado: {},//<-- el seleccionado estará aquí
	myOptions: [],
	rolSelected: '',
	showDialogEdit: false,
	showDialogTopside: false,
	dialogItem: {},
	ajustesTopside: [],
	searched: [],
	isLoading: false,
	error: false,
	criterioC: "",
	criterioD: "",
	criterioN: "",
	formEdit: {
		cuenta: "",
		descripcion: '',
		niif : '',
		niif_desc : '',
		saldo_actual : 0,
		funcional1 : 0,
		promedio1 : 0,
		interna1 : 0,
		funcional2 : 0,
		promedio2 : 0,
		interna2 : 0,
		ajuste_actual : 0,
		ajuste_funcional1 : '',
		ajuste_promedio1 : '',
		ajuste_interna1 : '',
		ajuste_funcional2 : '',
		ajuste_promedio2 : '',
		ajuste_interna2 : '',
		niif1 : '',
		porcentaje: 1,
		u_crea: 1,
		tipo_ajuste: 0,
	},
	formTopside: { },
	reclasifica:false,
	idCompania:1,
	vigencia:'',
	companias : [],
	companiaUsuario : [],
	dialogCompanias : [],
	fechas : [],
	dialogFechas : [],
	reportes : [],
	dialogReportes : '',
	fgl_consultar: false,
	columnas: 0,
	cuentas: [],
	selCuentas: [],
	niif: [],
	niifCuentas: [],
	niifSeleccionados: [],
	empresa: 0,
	generado: false,
	codigoCompanias: "",
	moneda: 0,
	v_cuenta: '',
	v_id: '',
	v_niif: '',
	v_niif1: '',
	v_descripcion: '',
	v_ajuste_funcional1: 0,
	v_ajuste_promedio1: 0,
	v_ajuste_interna1: 0,
	v_ajuste_funcional2: 0,
	v_ajuste_promedio2: 0,
	v_ajuste_interna2: 0,
	vt_ajuste_funcional1: 0,
	vt_ajuste_promedio1: 0,
	vt_ajuste_interna1: 0,
	vt_ajuste_funcional2: 0,
	vt_ajuste_promedio2: 0,
	vt_ajuste_interna2: 0,
}),
computed:{
	labels(){
	if (this.$store.state._language == "es-ES") {
		return {
			title: "BALANCE",
			secuencia: "SEQ",
			cuenta: "Cuenta",
			criterio: "Criterio",
			descripcion: "Descripcion",
			niif : "Niif",
			niif1 : "Reclasificacion",
			saldo_periodo_anterior: "Saldo Periodo Anterior",
			saldo_actual: "Saldo",
			funcional1: "Funcional",
			ajuste_actual: "Ajuste Moneda",
			ajuste_funcional1: "Ajuste Funcional",
			alterna1: "Alterna",
			promedio1: "Promedio",
			interna1: "Interna",
			funcional2: "Funcional2",
			promedio2: "Promedio2",
			interna2: "Interna2",
			saldo_periodo_actual: "Saldo A",
			diferencia: "Diferencia",
			funcional_dif: "Dif Funcional",
			alterna_dif: "Dif Alterna",
			compania: "Compañia",
			empty: "Sin Seleccion",
			notFoundMessage: "Sin Informacion",
			consultar : "Consultar",
			crear : "+",
			ajustes : "Ajustes",
			consolidado : "Consolidado",
			moneda : "Moneda",
			vigencia : "Vigencia...",
			reporte : "Reporte...",
			companias : "Compania..."
		}
		}else {
			return {
				title: "NIIF CHECKING BALANCE",
				secuencia: "SEQ",
				cuenta: "Account",
				criterio: "Criterion",
				descripcion: "Description",
				niif : "NIIF",
				niif1 : "Reclassification",
				funcional1: "Currency Funcional",
				alterna1: "Alternate",
				promedio1: "Average",
				interna1: "Internal",
				funcional2: "Alternative",
				promedio2: "Average",
				interna2: "Internal",
				saldo_actual: "Currency Balance",
				saldo_periodo_anterior: "Previous Period Balance",
				saldo_periodo_actual: "Current Period Balance",
				diferencia: "Difference",
				funcional_dif: "Dif Funcional",
				alterna_dif: "Dif Alternate",
				monto: "Amount",
				compania: "Company",
				empty: "Without Information",
				notFoundMessage: "Without Information",
				consultar : "Consult",
				crear : "+",
				ajustes : "Settings",
				consolidado : "Consolidated",
				moneda : "Currency",
				vigencia : "Validity...",
				reporte : "Report...",
				companias : "Company..."
			}
		}
	}
},
methods: {
	seleccionaCompania() {
		setTimeout(()=>{
			let buscaMoneda = this.companias.filter((item) =>
				item.id==this.dialogCompanias)
			this.moneda = buscaMoneda[0].acronimo
			this.consultar()
		},500)
	},
	seleccionaCuenta() {
		let a_descripcion = this.selCuentas.filter((item) =>
			item.cuenta==this.formEdit.cuenta)
		this.formEdit.descripcion = a_descripcion[0].descripcion
		this.formEdit.niif = a_descripcion[0].niif
		a_descripcion = this.niif.filter((item) =>
			item.niif==this.formEdit.niif)
		this.formEdit.niif_desc = a_descripcion[0].descripcion
		Balance.getSaldoNiif(this.idCompania,this.dialogFechas,this.formEdit.cuenta,this.formEdit.niif)
		.then((result) => {
			this.formEdit.saldo_actual = result.data.saldos.saldo_actual
			this.formEdit.funcional1 = result.data.saldos.funcional1
			this.formEdit.promedio1 = result.data.saldos.promedio1
			this.formEdit.interna1 = result.data.saldos.interna1
			this.formEdit.funcional2= result.data.saldos.funcional2
			this.formEdit.promedio2 = result.data.saldos.promedio2
			this.formEdit.interna2 = result.data.saldos.interna2
			this.porcentaje = this.formEdit.funcional1/this.formEdit.saldo_actual
			this.formEdit.saldo_actual = this.formato(result.data.saldos.saldo_actual)
			this.formEdit.funcional1 = this.formato(result.data.saldos.funcional1)
		})
	},
	f_datos_cuenta(p_id) {
		const cuenta = this.cuentas.find((cuenta) => 
			cuenta.id === p_id)
		this.v_descripcion = cuenta.descripcion
		this.v_cuenta = cuenta.cuenta
		this.v_niif = this.f_niifCuenta1(cuenta.cuenta)
	},
	consultar() {
		this.fgl_consultar = false
		if	(this.dialogCompanias && this.dialogFechas && this.dialogReportes) {
			this.idCompania=this.dialogCompanias
			this.vigencia=this.dialogFechas
			this.reporte=this.dialogReportes
			this.generado=false
			let v_tipo_ajuste = -1
			if	(this.$route.name == "ajustesTopside") {
				v_tipo_ajuste = 0
			}
			if	(this.$route.name == "reclasificaBalance") {
				v_tipo_ajuste = 1
			}
			PlanConsolidacion.getPlanConsPorCompVige(
				this.dialogFechas,
				this.dialogReportes)
			.then((result) => {
				this.niif = result.data.planConsNiif
			})
			PlanConsolidacion.getPlanConsPorCuentaVige(
				this.dialogCompanias,
				this.dialogFechas,
				this.dialogReportes)
			.then((result) => {
				this.niifCuentas = result.data.planConsCuentas
				setTimeout(()=>{
					Balance.getAjustesTopside(this.idCompania,this.vigencia,this.dialogReportes,v_tipo_ajuste)
					.then((result) => {
						this.ajustesTopside = result.data.ajustesTopside;
						this.searched = this.ajustesTopside;
					})
					.catch(() => {
						this.error = true;
					});
				},1000)
			})
			setTimeout(()=>{
				this.fgl_consultar=true
				if	(this.ajustesTopside.length > 0 &&
					!this.reclasifica ) {
					this.f_calculeTopside()
					let r_topside = {}
					r_topside.id = ''
					r_topside.cuenta = ''
					r_topside.descripcion = 'TOTAL'
					r_topside.niif = ''
					r_topside.ajuste_funcional1=this.vt_ajuste_funcional1
					r_topside.ajuste_promedio1=this.vt_ajuste_promedio1
					r_topside.ajuste_alterna1=this.vt_ajuste_alterna1
					r_topside.ajuste_funcional2=this.vt_ajuste_funcional2
					r_topside.ajuste_promedio2=this.vt_ajuste_promedio2
					r_topside.ajuste_alterna2=this.vt_ajuste_alterna2
					this.ajustesTopside.push(r_topside)
				}
			},1000)
		}
	},
	crear() {
		this.showDialogEdit = true
		this.v_cuenta = ''
		this.v_descripcion = ''

		this.f_calculeTopside()
		this.v_ajuste_funcional1=-this.vt_ajuste_funcional1
		this.v_ajuste_promedio1=-this.vt_ajuste_promedio1
		this.v_ajuste_interna1=-this.vt_ajuste_interna1
		this.v_ajuste_funcional2=-this.vt_ajuste_funcional2
		this.v_ajuste_promedio2=-this.vt_ajuste_promedio2
		this.v_ajuste_interna2=-this.vt_ajuste_interna2

		Balance.getCuentas(this.dialogCompanias,this.dialogFechas)
		.then((result) => {
			this.cuentas = result.data.cuentas
			this.selCuentas = this.cuentas
		})
		PlanConsolidacion.getPlanConsPorCompVige(
			this.dialogFechas,
			this.dialogReportes)
		.then((result) => {
			this.niif = result.data.planConsNiif
		})
		PlanConsolidacion.getPlanConsPorCuentaVige(
			this.dialogCompanias,
			this.dialogFechas,
			this.dialogReportes)
		.then((result) => {
			this.niifCuentas = result.data.planConsCuentas
		})
		
	},
	f_calculeTopside() {
		this.vt_ajuste_funcional1=0
		this.vt_ajuste_promedio1=0
		this.vt_ajuste_interna1=0
		this.vt_ajuste_funcional2=0
		this.vt_ajuste_promedio2=0
		this.vt_ajuste_interna2=0
		if	(this.reclasifica) {
			return
		}
		this.ajustesTopside.forEach((cuenta) => {
			if	(cuenta.id !== '') {
				this.vt_ajuste_funcional1=parseFloat(this.vt_ajuste_funcional1)+parseFloat(cuenta.ajuste_funcional1)
				this.vt_ajuste_promedio1=parseFloat(this.vt_ajuste_promedio1)+parseFloat(cuenta.ajuste_promedio1)
				this.vt_ajuste_interna1=parseFloat(this.vt_ajuste_interna1)+parseFloat(cuenta.ajuste_interna1)
				this.vt_ajuste_funcional2=parseFloat(this.vt_ajuste_funcional2)+parseFloat(cuenta.ajuste_funcional2)
				this.vt_ajuste_promedio2=parseFloat(this.vt_ajuste_promedio2)+parseFloat(cuenta.ajuste_promedio2)
				this.vt_ajuste_interna2=parseFloat(this.vt_ajuste_interna2)+parseFloat(cuenta.ajuste_interna2)
			}
		})
	},
	grabeAjusteTopside() {
		let v_error = false
		let v_mensaje = ''
		this.formEdit.cuenta = this.v_cuenta
		this.formEdit.descripcion = this.v_descripcion
		this.formEdit.ajuste_funcional1 = this.v_ajuste_funcional1
		this.formEdit.ajuste_promedio1 = this.v_ajuste_promedio1
		this.formEdit.ajuste_interna1 = this.v_ajuste_interna1
		this.formEdit.ajuste_funcional2 = this.v_ajuste_funcional2
		this.formEdit.ajuste_promedio2 = this.v_ajuste_promedio2
		this.formEdit.ajuste_interna2 = this.v_ajuste_interna2
		this.formEdit.codReporte = this.dialogReportes
		this.formEdit.niif = this.v_niif
		if	(this.$route.name == "ajustesTopside") {
			this.formEdit.tipo_ajuste = 0
		}
		if	(this.$route.name == "reclasificaBalance") {
			this.formEdit.tipo_ajuste = 1
		}
		if	(this.formEdit.cuenta == '') {
			v_mensaje = "Falta Cuenta"
			this.$toast.error(v_mensaje)
			return
		}
		if	(this.formEdit.tipo_ajuste == 1 ) {
			this.formEdit.niif1 = this.v_niif1
			if	(this.formEdit.niif1 == '') {
				if	(v_error) {
					v_mensaje = v_mensaje + ",Falta Niif(Reclasificado)"
				} else	{
					v_mensaje = "Falta Niif(Reclasificado)"
				}
				v_error = true
			} else	{
				if	(this.formEdit.niif == this.formEdit.niif1) {
					if	(v_error) {
						v_mensaje = v_mensaje + ",Niif y Niif(Reclasificado) debe ser diferentes"
					} else	{
						v_mensaje = "Niif y Niif(Reclasificado) deben ser diferentes"
					}
					v_error = true
				}
			}
		}
		if	(v_error) {
			this.$toast.error(v_mensaje)
			return
		}

		if	(v_error) {
			this.$toast.error(v_mensaje)
			return
		}
		this.showDialogEdit= false
		this.formEdit.u_crea = this.$store.state.userId
		Balance.grabeAjusteTopside(this.dialogCompanias,this.dialogFechas,this.formEdit)
		.then((result) => {
			if	(result.status == 200) {
				this.$toast.success("Informacion Grabada")
			} else	this.$toast.error("Problema al grabar")
		})
		.catch(() => {
			this.error = true
		});
		setTimeout(()=>{
			this.consultar()
		},500)
	},
	borraAjusteTopside(secuencia) {
		this.showDialogTopside= false
		let v_tipo_ajuste = -1
		if	(this.$route.name == "ajustesTopside") {
			v_tipo_ajuste = 0
		}
		if	(this.$route.name == "reclasificaBalance") {
			v_tipo_ajuste = 1
		}
		Balance.borraAjusteTopside(this.dialogCompanias,this.dialogFechas,secuencia,v_tipo_ajuste)
		.then((result) => {
			if	(result.status == 200) {
				this.$toast.success("Informacion Borrada")
			} else	this.$toast.error("Problema al Borrar")
		})
		.catch(() => {
			this.error = true
		});
		setTimeout(()=>{
			this.consultar()
		},500)
	},
	f_titulo() {
		if	(this.$route.name == "ajustesTopside") {
			let titulo = "AJUSTES TOPSIDE"
			let tituloEN = "TOPSIDE ADJUSTMENT"

			let tituloConsolidado = ""
			if	(this.$store.state._language == "es-ES") {
				tituloConsolidado = titulo
			} else	{
				tituloConsolidado = tituloEN
			}
			return tituloConsolidado
		}
		if	(this.$route.name == "reclasificaBalance") {
			let titulo = "RECLASIFICACION SALDOS DE BALANCES"
			let tituloEN = "BALANCE RECLASSIFICATION"

			let tituloConsolidado = ""
			if	(this.$store.state._language == "es-ES") {
				tituloConsolidado = titulo
			} else	{
				tituloConsolidado = tituloEN
			}
			return tituloConsolidado
		}
	},
	f_niifCuenta(cuenta) {
		let a_niif = this.niifCuentas.filter((item) =>
			item.cuenta==cuenta)
		const v_niif = a_niif[0].niif
		return this.f_niif(v_niif)
	},
	f_niifCuenta1(cuenta) {
		let a_niif = this.niifCuentas.filter((item) =>
			item.cuenta==cuenta)
		const v_niif = a_niif[0].niif
		return v_niif
	},
	f_niif(niif) {
		let a_descripcion = this.niif.filter((item) =>
			item.niif == niif)
		if	(a_descripcion.length > 0) {
			return a_descripcion[0].nameNiif
		}
		return ""
	},
	formato(numero) {
		return  new Intl.NumberFormat('en-US',{ maximumFractionDigits: 0}).format(numero)
	},
	f_muestra(r_topside) {
		if	(r_topside.id === '') {
			return
		}
		this.showDialogTopside=true
		this.formTopside = r_topside
		this.formTopside.niif_desc = this.f_niif(r_topside.niif)
		this.formTopside.niif1_desc = this.f_niif(r_topside.niif1)
	},
	f_criterio_cuenta() {
		this.criterioD=''
		this.criterioN=''
		this.formEdit.cuenta = ''
		this.formEdit.descripcion = ''
		this.formEdit.niif = ''
		this.formEdit.niif_desc = ''
		this.formEdit.saldo_actual = ''
		this.formEdit.funcional1 = ''
		this.porcentaje = 1
		this.formEdit.saldo_actual = ''
		this.formEdit.funcional1 = ''
		const criterio = "^"+this.criterioC
		if	(this.criterioC.length ==0) {
			this.selCuentas = this.cuentas
		} else {
			this.selCuentas = this.cuentas.filter(
			(cuenta) => cuenta.cuenta.match(criterio)>0)
		}
	},
	f_criterio_descripcion() {
		this.criterioC=''
		this.criterioN=''
		this.formEdit.cuenta = ''
		this.formEdit.descripcion = ''
		this.formEdit.niif = ''
		this.formEdit.niif_desc = ''
		this.formEdit.saldo_actual = ''
		this.formEdit.funcional1 = ''
		this.porcentaje = 1
		this.formEdit.saldo_actual = ''
		this.formEdit.funcional1 = ''
		if	(this.criterioD.length == 0) {
			this.selCuentas = this.cuentas
		} else {
			this.selCuentas = this.cuentas.filter(
			(cuenta) => cuenta.descripcion.toUpperCase().indexOf(this.criterioD.toUpperCase())>=0)
		}
	},
	f_criterio_niif() {
		this.criterioC=''
		this.criterioD=''
		this.formEdit.cuenta = ''
		this.formEdit.descripcion = ''
		this.formEdit.niif = ''
		this.formEdit.niif_desc = ''
		this.formEdit.saldo_actual = ''
		this.formEdit.funcional1 = ''
		this.porcentaje = 1
		this.formEdit.saldo_actual = ''
		this.formEdit.funcional1 = ''
		if	(this.criterioN.length == 0) {
			this.selCuentas = this.cuentas
		} else {
			this.niifSeleccionados = this.niif.filter(
			(niif) => niif.descripcion.toUpperCase().indexOf(this.criterioN.toUpperCase())>=0)

			this.selCuentas = this.cuentas.filter(
			(cuenta) => cuenta.niif in this.niifSeleccionados)
		}
	}
},
mounted() {
	this.isLoading = true;

	this.reclasifica = false
	if	(this.$route.name == "reclasificaBalance") {
		this.reclasifica = true
	}
	Balance.leaCompaniaUsuario()
	.then ((result) => {
		this.companiaUsuario = result.data.companiaUsuario
	})

	Balance.getCompanias(true)
	.then ((result) => {
		this.companias = result.data.companias
		this.dialogCompanias = []
		this.codigoCompanias = "{"
		let primero = true
		let v_companias = []
		result.data.companias.forEach((element) => {
			const v_usuario = this.companiaUsuario.find((r_usuario) => 
				r_usuario.id_usuario == this.$store.state.userId &&
				r_usuario.id_compania == element.id)
			if	(this.$store.state.userRole==1 || v_usuario) {
				v_companias.push(element)
			}
		})
		this.companias=v_companias
		result.data.companias.forEach(element => {
		this.dialogCompanias.push(element.id);
		if	(!primero) {
			this.codigoCompanias=this.codigoCompanias+","
		}
		primero=false;
		this.codigoCompanias=this.codigoCompanias+"\"id"+element.id+"\":"+
			"\""+element.codigo+"\""
		})
		this.codigoCompanias=this.codigoCompanias+"}"
		this.codigoCompanias=JSON.parse(this.codigoCompanias)
	})
	.catch(() => {
		this.error = true
	});
	Balance.getFechas()
	.then ((result) => {
		this.fechas = ordenefechas(result.data.fechas)
		this.dialogFechas = []
		result.data.fechas.forEach(element => {
		this.dialogFechas.push(element.fecha);
		})
	})
	.catch(() => {
		this.error = true
	});
	Balance.getReportes()
	.then ((result) => {
		this.reportes = result.data.reportes
	})
	.catch(() => {
		this.error = true
	});
	this.isLoading = true;
},
};
</script>

<style src="../main.css"></style>
