<template>
  <div class="container">
    <div class="col-md-12"><h2>{{ labels.title }}</h2></div>
    <div class="row mt-5">
        <div class="col-md-3 h5">
            <select id="selReporte" name="" v-model="selReporte" v-on:click="f_actualiza_reporte()" >
                <option value="">{{labels.reporte}}...</option>
                <option v-for="reporte in reportes" :key="reporte.codigo" :value="reporte.codigo">{{reporte.codigo}}-{{reporte.nombre}}</option>
            </select>
        </div>
        <div class="col-md-4 h5">
            <select id="selVigencia" name="" v-model="selVigencia" v-on:click="f_actualiza_reporte()">
                <option value="">{{labels.vigencia}}...</option>
                <option v-for="vigencia in listVigencia" :key="vigencia.vigencia"> {{ vigencia.vigencia }}</option>
            </select>
        </div>
        <div class="col-md-5 h5">
            <select id="company" name="" v-model="companyId" v-on:click="f_actualiza_reporte()">
                <option value="">{{labels.company}}...</option>
                <option v-for="company in listCompany" :value="company.id" :key="company.id">{{ company.acronym+" "+company.nombre }}</option>
            </select>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <b-button @click="consulReporteECP()" id="consult-btn">{{ labels.consultar }}</b-button>
        </div>
    </div>
    <div class="row col-md-12 mt-4" v-if="showRowCol">
        <div class="col-md-1"></div>
        <div class="col-md-5">
            <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="newFila">
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div class="col-md-5">
            <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="newColumna">
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div class="col-md-1"></div>
    </div>
    <div class="row mt-3" v-if="showRowCol">
        <div class="col-md-1"></div>
        <div class="col-md-5">
            <!-- <EcpFila/> -->
            <div class="headNivUno">
                {{ labels.fila }}
            </div>
            <div class="bodyFila" v-if="listFila.length == 0">
                <!-- <input type="text" id="inpFila" v-model="fila" class="form-control" placeholder="Fila..."> -->
                {{ labels.notRow }}
            </div>
            <div v-else>
                <div class="bodyFila" v-for="fila in listFila" :key="fila.id">
                    {{ fila.fila }} . {{ fila.descripcion }}
                </div>
                <!-- <input type="text" id="inpFila" v-model="fila" class="form-control" placeholder="Fila..."> -->
            </div>
        </div>
        <div class="col-md-5">
            <!-- <EcpColumnaVue/> -->
            <div class="headNivUno">{{ labels.columna }}</div>
            <div class="bodyColumna" v-if="listColumna.length == 0">
                <!-- <input type="text" id="inpColumna" v-model="columna" class="form-control" placeholder="Columna..."> -->
                {{ labels.notRow }}
            </div>
            <div v-else>
                <div class="bodyColumna" v-for="columna in listColumna" :key="columna.id">
                    {{ columna.columna }} . {{ columna.descripcion }}
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
    <div class="row mt-5" v-if="showRowCol">
        <div class="col-md-12">
            <b-button @click="consultECP()" id="consult-btn">{{ labels.detail }}</b-button>
        </div>
    </div>
    <NewFilaVue :showFila="showModFila" @closeModal="cierraModal" @reloadTblFila="recargaTblFila"/>
    <NewColumna :showColumna="showModColumna" @closeModal="cierraModal" @reloadTblColumna="recargaTblColumna"/>
    <EcpFilaColumna v-if="showListFilaCol" :vigCompSel="infoVigeCom" :consulDetallEcp="infoVigeCom"/>
  </div>
</template>
<script>

import Balance from '../../services/BalanceNiif'
import PlanCuentas from '../../services/PlanCuentas'
import Company from '../../services/Company'
import EcpReporte from '../../services/EcpReporte'
// import EcpFila from './EcpFila.vue'
// import EcpColumnaVue from './EcpColumna.vue'
// import EcpBtnAgregarVue from './EcpBtnAgregar.vue'
import NewFilaVue from './NewFila.vue'
import NewColumna from './NewColumna.vue'
import EcpFilaColumna from './EcpFilaColumna.vue'

export default {
    name:'EcpReporte',
    components:{
        NewFilaVue,
        NewColumna,
        EcpFilaColumna
        // EcpFila,
        // EcpColumnaVue,
        // EcpBtnAgregarVue
    },
    data: () => ({
        selVigencia : "",
        selVigenciaAnterior : "",
        companyId : "",
        companyIdAnterior : "",
        listVigencia : [],
        listCompany : [],
        companiaUsuario : [],
        dataVigenCompa: {},
        showRowCol: false,
        fila : "",
        listFila : [],
        columna : "",
        listColumna : [],
        showModFila: false,
        showModColumna: false,
        showListFilaCol: false,
        infoVigeCom : {},
	selReporte: '',
	selReporteAnterior: '',
	reportes : [],
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title: "Reporte ECP",
                    reporte: "Reporte:",
                    vigencia: "Vigencia:",
                    company: "Compañía:",
                    consultar: "Consultar",
                    fila: "FILA",
                    columna: "COLUMNA",
                    detail: "DETALLE",
                    notRow: "No hay Registros"
                }
            }else{
                return {
                    title: "ECP Report",
                    reporte: "Report:",
                    vigencia: "Validity:",
                    company: "Company:",
                    consultar: "Consult",
                    fila: "ROW",
                    columna: "COLUMN",
                    detail: "DETAIL",
                    notRow: "There are not records"
                }
            }
        }
    },
    methods:{
	f_actualiza_reporte() {
		if	(this.selReporte != this.selReporteAnterior) {
			this.showRowCol = false
			this.showModFila = false
			this.showModColumna = false
			this.showListFilaCol = false
			this.selReporteAnterior = this.selReporte
		}
		if	(this.selVigencia != this.selVigenciaAnterior) {
			this.showRowCol = false
			this.showModFila = false
			this.showModColumna = false
			this.showListFilaCol = false
			this.selVigenciaAnterior = this.selVigencia
		}
		if	(this.companyId != this.companyIdAnterior) {
			this.showRowCol = false
			this.showModFila = false
			this.showModColumna = false
			this.showListFilaCol = false
			this.companyIdAnterior = this.companyId
		}
	},
        consulReporteECP(){
            
            if(this.selVigencia != "" && this.companyId != "" && this.selReporte != ""){
                this.showRowCol = true

                this.dataVigenCompa = {
                    "vigencia" : this.selVigencia,
                    "compania" : this.companyId,
                    "codReporte" : this.selReporte
                }
            
            }else{
                this.$toast.error("Diligenciar el campo vigencia y/o compañia y/o reporte")
            }
        },
        consultECP(){
            this.showListFilaCol = true
        },
        newFila(){
            // console.log("NewFila")
            this.showModFila = true
        },
        newColumna(){
            // console.log("NewColumna")
            this.showModColumna = true
        },
        cierraModal(val){

            this.showModFila = val
            this.showModColumna = val
        },
        recargaTblFila(val){

            this.showModFila = val

            EcpReporte.getAllFila()
            .then((res) => {
                this.listFila = res.data.allFila
            })
            .catch((err) => {
                console.log(err.message)
                this.listFila = []
            })
        },
        recargaTblColumna(val){

            this.showModColumna = val

            EcpReporte.getAllColumna()
            .then((res) => {
                this.listColumna = res.data.allColumn
            })
            .catch((err) => {
                console.log(err.message)
                this.listColumna = []
            })
        }
    },
    mounted(){

	Balance.leaCompaniaUsuario()
	.then ((result) => {
		this.companiaUsuario = result.data.companiaUsuario
	})
        PlanCuentas.getVigenciaPlancuentas()
        .then((result) => {
            // console.log(result.data.getVigencia)
            this.listVigencia = result.data.getVigencia
        })
        .catch((err) => {
            console.log(err)
        })

        Company.getAllCompanies()
        .then((result) => {
            // console.log(result)
            this.listCompany = result.data.companies
		let v_companias = []
		result.data.companies.forEach((element) => {
			const v_usuario = this.companiaUsuario.find((r_usuario) => 
				r_usuario.id_usuario == this.$store.state.userId &&
				r_usuario.id_compania == element.id)
			if	(this.$store.state.userRole==1 || v_usuario) {
				v_companias.push(element)
			}
		})
		this.listCompany=v_companias
        })
        .catch((err) => {
            console.log(err)
        })

        EcpReporte.getAllFila()
        .then((res) => {
            this.listFila = res.data.allFila
        })
        .catch((err) => {
            console.log(err.message)
            this.listFila = []
        })

        EcpReporte.getAllColumna()
        .then((res) => {
            this.listColumna = res.data.allColumn
            // console.log(this.listColumna)
        })
        .catch((err) => {
            console.log(err.message)
            this.listColumna = []
        })
	Balance.getReportes()
	.then ((result) => {
		this.reportes = result.data.reportes.filter((reporte) => reporte.principal==1)
	})
	.catch(() => {
		this.error = true
	})

    },
    watch:{
        selReporte(valNew){
            // console.log(valNew)
            this.infoVigeCom.codReporte = valNew
        },
        selVigencia(valNew){
            // console.log(valNew)
            this.infoVigeCom.vigencia = valNew
        },
        companyId(valNew){
            // console.log(valNew)
            this.infoVigeCom.companyId = valNew
        }
    }
}
</script>
<style src="../main.css"></style>
