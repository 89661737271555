import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login'
import CreateInvoice from '@/components/invoices/Create'
import List from '@/components/invoices/List'
import Auth from '@/components/auth/SetPassword'

import ProviderList from '@/components/complement/List'


import Dashboard from '@/components/start/Dashboard'
import Administrador from '@/components/administrador/Administrador'
import Menu from '@/components/administrador/menus/Menu'
import Submenus from '@/components/administrador/submenus/Submenus'
import Parametrizacion from '@/components/parametrizacion/Parametrizacion'
import ManageUsers from '@/components/users/Manage'
import CreateUsers from '@/components/users/Create'
import AdminRoles from '@/components/roles/Manage'
import AdminCurrency from '@/components/currency/Manage'
import CreateCurrency from '@/components/currency/Create'
import CreateRoles from '@/components/roles/Create'
import TasaCambio from '@/components/tasaCambio/TasaCambio'
import TasasPatrimonio from '@/components/tasaCambio/TasasPatrimonio'
import PlanConsolidacion from '@/components/planConsolidacion/PlanConsolidacion'
import Niif from '@/components/niif/Niif'
import AjustesConsol from '@/components/ajustesConsol/AjustesConsol'
import SegundoNivel from '@/components/planConsolidacion/SegundoNivel'
import Company from '@/components/company/Manage'
import UsuarioCompania from '@/components/company/UsuarioCompania'
import PlanCuentas from '@/components/planCuentas/PlanCuentas'
/* import Cargue from '@/components/cargue/Cargue' */
import CargueBalance from '@/components/cargue/CargueBalance'
import CargueFormato from '@/components/cargue/CargueFormato'
import CargueInterco from '@/components/cargue/CargueInterco'
import BalanceNiif from '@/components/consolidacion/BalanceNiif'
import Consolidation from '@/components/consolidation/Consolidation'
import ReporteEFE from '@/components/consolidacion/ReporteEFE'
import ReporteECP from '@/components/consolidacion/ReporteECP'
import Eliminaciones from '@/components/consolidacion/Eliminaciones'
import AjustesTopside from '@/components/consolidacion/AjustesTopside'
import NiifNoControlante from '@/components/parametrizacion/NiifNoControlante'
import RedefinicionFilas from '@/components/parametrizacion/redefinicionFilas/RedefinicionFilas'
import Notas from '@/components/parametrizacion/notas/Notas'
import TipoReporte from '@/components/parametrizacion/tipoReporte/TipoReporte'
import Niveles from '@/components/niveles/Niveles'
import EcpReporte from '@/components/ecpreporte/EcpReporte'
import ManageApprover from '@/components/approvers/Manage'
import CreateApprover from '@/components/approvers/Create'
import DisassociateApprover from '@/components/approvers/Disassociate'
import CreateProvider from '@/components/complement/Create'
// import Storage from "../main";
import store from '../store'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/administrador',
    name: 'administrador',
    component: Administrador,
    // children:[
    //   {
    //     path: '/administrador/menus',
    //     name: 'menus',
    //     component: Menu,
    //   }
    // ]
  },
  {
    path: '/submenus',
    name: 'submenus',
    component: Submenus
  },
  {
    path: '/menus',
    name: 'menus',
    component: Menu,
  },
  {
    path: '/niifNoControlante',
    name: '/niifNoControlante',
    component: NiifNoControlante
  },
  {
    path: '/redefinicionFilas',
    name: '/redefinicionFilas',
    component: RedefinicionFilas
  },
  {
    path: '/notas',
    name: '/notas',
    component: Notas
  },
  {
    path: '/parametrizacion',
    name: 'parametrizacion',
    component: Parametrizacion,
    /*children:[
      {
        path: 'currency',
        name: 'currency',
        component: AdminCurrency,
        // beforeEnter: (to, from, next) => {
        //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        //     next()
        //   }else{
        //     next({name: 'login'})
        //   }
        // }
      },
      {
        path: '/currency/create',
        name: 'currency-create',
        component: CreateCurrency,
        // beforeEnter: (to, from, next) => {
        //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        //     next()
        //   }else{
        //     next({name: 'login'})
        //   }
        // }
      },
      {
        path: 'tasaCambio',
        name: 'tasaCambio',
        component: TasaCambio,
        // beforeEnter: (to, from, next) => {
        //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        //     next()
        //   }else{
        //     next({name: 'login'})
        //   }
        // }
      }
    ]*/
  },
  {
    path: '/reportes',
    name: '/reportes',
    component: TipoReporte
  },
  {
    path: '/providers',
    name: 'providers',
    component: ProviderList,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM' ){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/provider/create',
    name: 'provider-create',
    component: CreateProvider,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/invoice/create',
    name: 'create-invoice',
    component: CreateInvoice,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM' ){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/list',
    name: 'list',
    component: List,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM' || store.state.userRole == 'CHK' || store.state.userRole == 'APP'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/pending',
    name: 'pending',
    component: List,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM' || store.state.userRole == 'CHK' || store.state.userRole == 'AGN'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/users',
    name: 'users',
    component: ManageUsers,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: CreateUsers,
    beforeEnter: (to, from, next) => {
      // if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        next()
      // }else{
      //   next({name: 'login'})
      // }
    }
  },
  {
    path: '/roles',
    name: 'roles',
    component: AdminRoles,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/roles/create',
    name: 'roles-create',
    component: CreateRoles,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/approver',
    name: 'approver',
    component: ManageApprover,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/approver/create',
    name: 'approver-create',
    component: CreateApprover,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/approver/disassociate',
    name: 'approver-disassociate',
    component: DisassociateApprover,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/currency',
    name: 'currency',
    component: AdminCurrency,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/currency/create',
    name: 'currency-create',
    component: CreateCurrency,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/tasaCambio',
    name: 'tasaCambio',
    component: TasaCambio,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/tasasPatrimonio',
    name: 'tasasPatrimonio',
    component: TasasPatrimonio,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/company',
    name: 'company',
    component: Company,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/usuarioCompania',
    name: 'usuarioCompania',
    component: UsuarioCompania,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/planConsolidacion',
    name: 'planConsolidacion',
    component: PlanConsolidacion,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/planConsolidacion/segundoNivel/:primer_nivel',
    name: 'SegundoNivel',
    component: SegundoNivel,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/niif',
    name: 'niif',
    component: Niif,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/ajustesConsol',
    name: 'ajustesConsol',
    component: AjustesConsol,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
	path: '/cargue',
	name: '/cargue',
	component: CargueBalance,

    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
	path: '/cargueBalance',
	name: '/cargueBalance',
	component: CargueBalance,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
	path: '/cargueFormato',
	name: '/cargueFormato',
	component: CargueFormato,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
	path: '/cargueInterco',
	name: '/cargueInterco',
	component: CargueInterco,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
	path: '/cargueBalanceF2',
	name: '/cargueBalanceF2',
	component: CargueBalance,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
	path: '/cargueBalanceF3',
	name: '/cargueBalanceF3',
	component: CargueBalance,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
	path: '/cargueBalanceF4',
	name: '/cargueBalanceF4',
	component: CargueBalance,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
	path: '/cargueBalanceF5',
	name: '/cargueBalanceF5',
	component: CargueBalance,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
	path: '/cargueBalanceF6',
	name: '/cargueBalanceF6',
	component: CargueBalance,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/planCuentas',
    name: '/planCuentas',
    component: PlanCuentas,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/balanceNiif',
    name: 'balanceNiif',
    component: BalanceNiif,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/consolidation',
    name: 'consolidation',
    component: Consolidation
  },
  {
    path: '/reporteEsf',
    name: 'reporteEsf',
    component: BalanceNiif,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/reporteEri',
    name: 'reporteEri',
    component: BalanceNiif,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/reporteEfe',
    name: 'reporteEfe',
    component: ReporteEFE,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/reporteEcp',
    name: 'reporteEcp',
    component: ReporteECP,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/ecpReporte',
    name: 'ecpReporte',
    component: EcpReporte
  },
  {
    path: '/eliminaciones',
    name: 'eliminaciones',
    component: Eliminaciones,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/ajustesTopside',
    name: 'ajustesTopside',
    component: AjustesTopside,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/reclasificaBalance',
    name: 'reclasificaBalance',
    component: AjustesTopside,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/niveles/:tipo',
    name: 'niveles',
    component: Niveles
  },
  {
    path: '/home',
    name: 'home',
    component: Dashboard,
    // beforeEnter: (to, from, next) => {
    //   if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
    //     next()
    //   }else{
    //     next({name: 'login'})
    //   }
    // }
  },
  {
    path: '/account',
    name: 'account',
    component: Auth
  },  
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // let expiresIn = 0;
  // if(store.state.expiration){
  // const now = new Date();
  // expiresIn = (new Date(store.state.expiration)).getTime() - now.getTime();
  // }
  if (to.name !== 'login' && to.name !== 'account' && to.name !== 'registration' && !store.state.isAuthenticated) next({ name: 'login' })
  else next()
})

export default router
